import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useActionSnackbar } from 'hooks/useActionSnackbar';
import useFetcher from 'hooks/useFetcher';
import { Button, Grid } from '@mui/material';
import { isEmpty } from 'lodash';
import { LoadingButton } from '@mui/lab';
import { yupResolver } from '@hookform/resolvers/yup';

import { TextField, FormProvider } from 'components/hook-form';
import GridContainer from 'components/GridContainer';
import Error from 'components/Error';
import Loader from 'components/Loader';
import PageContainer from 'components/PageContainer';
import Iconify from 'components/Iconify';

import TypesPaymentsService from 'services/TypesPaymentsService';
import TypesPaymentsUpdateSchema from '../utils/validations/TypesPaymentsUpdateValidation';

function TypesPaymentsUpdate() {
  const [load, setLoading] = useState(false);

  const navigate = useNavigate();
  
  const { typePaymentId } = useParams();
  const { addActionSnackbar } = useActionSnackbar();

  const { data, loading, error } = useFetcher(TypesPaymentsService.findById(typePaymentId));

  const methods = useForm({
    resolver: yupResolver(TypesPaymentsUpdateSchema)
  });

  const {
    handleSubmit,
    formState: { isSubmitting }
  } = methods;

  const onSubmit = async data => {
    setLoading(true);

    try {
      const res = await TypesPaymentsService.update(typePaymentId, data);

      if (!isEmpty(res)) {
        addActionSnackbar({
          severity: 'success',
          message: 'Tipo de pagamento atualizado com sucesso!'
        });
        navigate(-1);
      }
    } catch (error) {
      addActionSnackbar({
        severity: 'error',
        message: error.message,
        statusList: error.badFields
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    methods.reset({
      name: data?.name || ''
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (error) return <Error {...{ error }} />;

  return (
    <PageContainer
      title="Atualização de Tipo de Pagamento"
      headerComponent={
        <Button
          onClick={() => navigate(-1)}
          variant="contained"
          to="#"
          startIcon={<Iconify icon="akar-icons:circle-x-fill" />}
        >
          Cancelar
        </Button>
      }
    >
      {loading ? (
        <Loader />
      ) : (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <GridContainer>
            <Grid item xs={12}>
              <TextField type="text" name="name" label="Nome" required />
            </Grid>

            <Grid item xs={12}>
              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting || load}
              >
                Atualizar
              </LoadingButton>
            </Grid>
          </GridContainer>
        </FormProvider>
      )}
    </PageContainer>
  );
}

export default TypesPaymentsUpdate;
