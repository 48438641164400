import { fDateTimeS } from "utils/formatTime";

export const TABLE_HEAD = [
  { id: 'name', label: 'Marca', alignRight: false, orderable: true },
];

export const formatTableData = (data) => ({
  key: data?.id,
  columns: [
    { render: data?.name } 
  ]
});

export const formatGalleryData = (data) => ({
  id: data?.id,
  src: data?.url,
  subtitle: `Criado em: ${fDateTimeS(data?.createdAt)}`
});
