import { Grid } from '@mui/material';
import PropTypes from 'prop-types';

const propTypes = {
  children: PropTypes.PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  spacing: PropTypes.number
};

function GridContainer({ spacing = 2, children }) {
  return (
    <Grid container {...{ spacing }}>
      {children}
    </Grid>
  );
}

GridContainer.propTypes = propTypes;

export default GridContainer;
