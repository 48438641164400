import HttpClient from 'utils/HttpClient';
import mountQuery from 'utils/mountQuery';

class EmployeeService {
  findAll(page = 1, limit = 10, sortBy = {}, filterBy = {}) {
    return mountQuery('/employees', { page, limit, sortBy, filterBy });
  }

  findById(id) {
    return `/employees/${id}`;
  }

  async create(data) {
    return HttpClient.post('/employees', data);
  }
  
  async sendCodeViaEmail(id) {
    return HttpClient.post(`/send-code/via/email/${id}`, {});
  }

  async confirmAccount(id, data) {
    return HttpClient.put(`/confirm-account`, id, {
      code: data.code
    });
  }

  async update(id, data, password) {
    return HttpClient.put(`/employees`, id, { 
      name: data.name,
      document: data.document,
      email: data.email,
      phone: data.phone,
      employeePassword: password
    });
  }

  async delete(id, password) {
    return HttpClient.delete(`/employees`, id, { data: { employeePassword: password } });
  }
}

export default new EmployeeService();
