import PropTypes from "prop-types"
import { useNavigate } from 'react-router-dom';
import { Card, Typography, Button } from '@mui/material';
import useAuth from "hooks/useAuth";

function Error({ error }) {
  const navigate = useNavigate();
  const { authenticated, signout } = useAuth();

  const message = error?.message;
  const status = error?.response?.status;

  if (authenticated && String(status) === '401') {
    signout();

    navigate(0);
  }

  const handleBack = () => navigate(-1);

  return (
    <Card sx={{ p: 5, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
      <Typography variant="h3" paragraph>
        Erro {status}!
      </Typography>
      <Typography sx={{ color: 'text.secondary', mb: 2 }}>
        "{message}"
      </Typography>
      <Button size="large" variant="contained" onClick={handleBack}>
        Voltar
      </Button>
    </Card>
  );
}

Error.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.any,
    response: PropTypes.shape({
      status: PropTypes.any
    })
  })
}

export default Error;
