import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { isEmpty } from 'lodash';
import { Grid } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { yupResolver } from '@hookform/resolvers/yup';

import PageContainer from 'components/PageContainer';
import GridContainer from 'components/GridContainer';
import {
  FormProvider,
  TextField,
  AsyncSelectField,
  SelectField
} from 'components/hook-form';

import { useActionSnackbar } from 'hooks/useActionSnackbar';
import ProductsService from 'services/ProductsService';
import BrandsService from 'services/BrandsService';

import CategoriesService from 'services/CategoriesService';
import ProductsCreateSchema from '../utils/validations/ProductsCreateValidation';

function setFormInitialValues() {
  return {
    name: '',
    description: '',
    price: 0,
    stock: 0,
    unit: 0,
    unitType: ''
  };
}

function ProductsCreate() {
  const [loading, setLoading] = useState(false);
  const [categorySearch, setCategorySearch] = useState('');
  const [brandSearch, setBrandSearch] = useState('');
  const [parentSearch, setParentSearch] = useState('');

  const { distributionCenterId } = useParams();
  const { addActionSnackbar } = useActionSnackbar();

  const navigate = useNavigate();
  const methods = useForm({
    resolver: yupResolver(ProductsCreateSchema),
    defaultValues: setFormInitialValues()
  });

  const {
    handleSubmit,
    formState: { isSubmitting }
  } = methods;

  const onSubmit = async data => {
    setLoading(true);

    try {
      if (data.variantOf === '') {
        data.variantOf = null;
      }

      data.distributionCenterId = distributionCenterId;

      const res = await ProductsService.create(data);

      if (!isEmpty(res)) {
        addActionSnackbar({
          severity: 'success',
          message: 'Produto cadastrado com sucesso!'
        });
        navigate(
          `/dashboard/distribution-centers/${distributionCenterId}/products/${res.id}`
        );
      }
    } catch (error) {
      addActionSnackbar({
        severity: 'error',
        message: error.message,
        statusList: error.badFields
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <PageContainer title="Cadastrar Produto">
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <GridContainer>
          <Grid item xs={12}>
            <TextField
              type="text"
              name="name"
              label="Nome"
              autoComplete="off"
              required
            />
          </Grid>

          <Grid item xs={12}>
            <AsyncSelectField
              name="categoryId"
              label="Categoria"
              url={CategoriesService.findByDistributionCenter(
                distributionCenterId,
                1,
                20,
                {},
                { search: categorySearch }
              )}
              onInputChange={setCategorySearch}
            />
          </Grid>

          <Grid item xs={12}>
            <AsyncSelectField
              name="brandId"
              label="Marca"
              url={BrandsService.findByDistributionCenter(
                distributionCenterId,
                1,
                20,
                {},
                { search: brandSearch }
              )}
              onInputChange={setBrandSearch}
              required
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              type="text"
              name="description"
              label="Descrição"
              autoComplete="off"
              multiline
              rows={3}
              required
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              type="number"
              name="price"
              label="Preço"
              autoComplete="off"
              required
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              type="number"
              name="stock"
              label="Estoque"
              autoComplete="off"
              required
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              type="number"
              name="unit"
              label="Unidade"
              autoComplete="off"
              required
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <SelectField
              name="unitType"
              label="Tipo da unidade"
              options={[
                { label: 'Quilogramas', value: 'kg' },
                { label: 'Gramas', value: 'g' },
                { label: 'Miligramas', value: 'mg' },
                { label: 'Unidade', value: 'un' },
                { label: 'Mililitros', value: 'ml' },
                { label: 'Litros', value: 'l' }
              ]}
              required
            />
          </Grid>

          <Grid item xs={12}>
            <AsyncSelectField
              name="variantOf"
              label="Variante de"
              url={ProductsService.findByDistributionCenter(
                distributionCenterId,
                1,
                20,
                {},
                { search: parentSearch }
              )}
              onInputChange={setParentSearch}
            />
          </Grid>

          <Grid item xs={12}>
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting || loading}
            >
              Cadastrar
            </LoadingButton>
          </Grid>
        </GridContainer>
      </FormProvider>
    </PageContainer>
  );
}

export default ProductsCreate;
