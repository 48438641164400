import { format, formatDistanceToNow } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

export function fTimeZone(date, timezone = 'America/Fortaleza') {
  const zonedDate = utcToZonedTime(new Date(date), timezone);

  return zonedDate;
}

export function fDate(date) {
  return format(new Date(date), 'dd MMMM yyyy');
}

export function fDateTime(date) {
  return format(new Date(date), 'dd/MM/yyyy HH:mm:ss');
}

export function fDateTimeSuffix(date) {
  return format(new Date(date), 'dd/MM/yyyy hh:mm p');
}

export function fDateTimeS(date, timezone = 'America/Fortaleza') {
  return format(fTimeZone(date, timezone), 'dd/MM/yyyy HH:mm:ss');
}

export function fToNow(date) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true
  });
}
