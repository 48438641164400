import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { isEmpty } from 'lodash';
import { Grid } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';

import PageContainer from 'components/PageContainer';
import GridContainer from 'components/GridContainer';
import { FormProvider, TextField } from 'components/hook-form';

import { useActionSnackbar } from 'hooks/useActionSnackbar';
import EmployeeService from 'services/EmployeeService';

import EmployeeCreateSchema from '../utils/validations/EmployeeCreateValidation';

function setFormInitialValues() {
  return {
    name: '',
    document: '',
    phone: '',
    email: '',
    password: ''
  };
}

function EmployeeCreate() {
  const [loading, setLoading] = useState(false);
  const { addActionSnackbar } = useActionSnackbar();

  const navigate = useNavigate();

  const methods = useForm({
    resolver: yupResolver(EmployeeCreateSchema),
    defaultValues: setFormInitialValues()
  });

  const {
    handleSubmit,
    formState: { isSubmitting }
  } = methods;

  const onSubmit = async data => {
    setLoading(true);

    try {
      data.typeId = 'ef1636e0-524a-4abb-a008-222f7145d0e3';

      const res = await EmployeeService.create(data);

      if (!isEmpty(res)) {
        addActionSnackbar({
          severity: 'success',
          message: 'Funcionário cadastrado com sucesso!'
        });

        navigate(`/dashboard/employee/${res.id}`);
      }
    } catch (error) {
      addActionSnackbar({
        severity: 'error',
        message: error.message,
        statusList: error.badFields
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <PageContainer title="Cadastrar Funcionários">
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <GridContainer>
          <Grid item xs={12}>
            <TextField type="text" name="name" label="Nome" autoComplete="off" required />
          </Grid>

          <Grid item xs={12}>
            <TextField
              type="text"
              name="document"
              label="Documento"
              mask="cpf"
              autoComplete="off"
              required
            />
          </Grid>

          <Grid item xs={12}>
            <TextField type="tel" name="phone" label="Telefone" mask="phone" autoComplete="off" />
          </Grid>

          <Grid item xs={12}>
            <TextField type="email" name="email" label="E-mail" autoComplete="off" required />
          </Grid>

          <Grid item xs={12}>
            <TextField type="password" name="password" label="Senha" autoComplete="off" required />
          </Grid>

          <Grid item xs={12}>
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting || loading}
            >
              Cadastrar
            </LoadingButton>
          </Grid>

        </GridContainer>
      </FormProvider>
    </PageContainer>
  );
}

export default EmployeeCreate;
