import React from 'react';
import PageContainerCentered from 'components/PageContainerCentered';
import ConfirmAccessForm from './form';

function ConfirmAccess(props) {
  return (
    <PageContainerCentered title="Confirmar Acesso" fixed>
      <ConfirmAccessForm {...props} />
    </PageContainerCentered>
  );
}

export default React.memo(ConfirmAccess);
