import { TableCell } from "@mui/material";

function renderColumns(columns) {
  return columns.map(({ align = 'left', render }, i) => (
    <TableCell key={String(Math.random() + i)} align={align}>
      {render}
    </TableCell>
  ));
}

export { renderColumns };