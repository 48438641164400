import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { isEmpty } from 'lodash';
import { Grid } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { yupResolver } from '@hookform/resolvers/yup';

import PageContainer from 'components/PageContainer';
import GridContainer from 'components/GridContainer';
import { FormProvider, TextField } from 'components/hook-form';

import { useActionSnackbar } from 'hooks/useActionSnackbar';
import TypesPaymentsService from 'services/TypesPaymentsService';

import TypesPaymentsCreateSchema from '../utils/validations/TypesPaymentsCreateValidation';

function setFormInitialValues() {
  return {
    name: ''
  };
}

function TypesPaymentsCreate() {
  const [loading, setLoading] = useState(false);

  const { distributionCenterId } = useParams();
  const { addActionSnackbar } = useActionSnackbar();
  const navigate = useNavigate();

  const methods = useForm({
    resolver: yupResolver(TypesPaymentsCreateSchema),
    defaultValues: setFormInitialValues()
  });

  const {
    handleSubmit,
    formState: { isSubmitting }
  } = methods;

  const onSubmit = async data => {
    setLoading(true);

    try {
      data.distributionCenterId = distributionCenterId;
      const res = await TypesPaymentsService.create(data);

      if (!isEmpty(res)) {
        addActionSnackbar({
          severity: 'success',
          message: 'Tipo de Pagamento cadastrado com sucesso!'
        });
        navigate(
          `/dashboard/distribution-centers/${distributionCenterId}/types-payments/${res.id}`
        );
      }
    } catch (error) {
      addActionSnackbar({
        severity: 'error',
        message: error.message,
        statusList: error.badFields
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <PageContainer title="Cadastrar Tipo de Pagamento">
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <GridContainer>
          <Grid item xs={12}>
            <TextField
              type="text"
              name="name"
              label="Nome"
              autoComplete="off"
              required
            />
          </Grid>

          <Grid item xs={12}>
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting || loading}
            >
              Cadastrar
            </LoadingButton>
          </Grid>
        </GridContainer>
      </FormProvider>
    </PageContainer>
  );
}

export default TypesPaymentsCreate;
