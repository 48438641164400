import 'utils/validation';
import { Suspense } from 'react';

import { AuthProvider } from 'contexts/AuthContext';
import { ActionSnackbarProvider } from 'hooks/useActionSnackbar';
import { ActionDialogProvider } from 'hooks/useActionDialog';
import { ConfirmAccessProvider } from 'hooks/useConfirmAccess';
import { BaseOptionChartStyle } from './components/chart/BaseOptionChart';
import Router from './routes';
import ThemeProvider from './theme';
import ScrollToTop from './components/ScrollToTop';

export default function App() {
  return (
    <ThemeProvider>
      <ScrollToTop />
      <BaseOptionChartStyle />
      <AuthProvider>
        <ConfirmAccessProvider>
          <ActionDialogProvider>
            <ActionSnackbarProvider>
              <Suspense fallback={<div>Loading...</div>}>
                <Router />
              </Suspense>
            </ActionSnackbarProvider>
          </ActionDialogProvider>
        </ConfirmAccessProvider>
      </AuthProvider>
    </ThemeProvider>
  );
}
