import { Link as RouterLink } from 'react-router-dom';
import { Button } from '@mui/material';

import Error from 'components/Error';
import Iconify from 'components/Iconify';
import Table from 'components/table/Table';
import PageContainer from 'components/PageContainer';

import useFetcher from 'hooks/useFetcher';
import useTable from 'hooks/useTable';

import DistributionCentersService from 'services/DistributionCentersService';

import { formatTableData, TABLE_HEAD } from '../utils/table';

export default function DistributionCenters() {
  const {
    page,
    order,
    orderBy,
    filterName,
    filterSearch,
    rowsPerPage,
    handleRequestSort,
    handleChangePage,
    handleChangeRowsPerPage,
    handleFilterByName
  } = useTable();

  const { data, loading, error } = useFetcher(
    DistributionCentersService.findAll(
      page,
      rowsPerPage,
      { [orderBy]: order },
      { search: filterSearch }
    )
  );

  if (error) return <Error {...{ error }} />;

  return (
    <PageContainer
      title="Painel de Centros de Distribuição"
      headerComponent={
        <Button
          variant="contained"
          component={RouterLink}
          to="/dashboard/distribution-centers/create"
          startIcon={<Iconify icon="eva:plus-fill" />}
        >
          Novo Centro
        </Button>
      }
    >
      <Table
        data={data} 
        loading={loading}
        tableHead={TABLE_HEAD}
        order={order}
        orderBy={orderBy}
        rowsPerPage={rowsPerPage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        filterName={filterName}
        onFilterName={handleFilterByName}
        onRequestSort={handleRequestSort}
        formatTableData={formatTableData}
        to="./"
      />
    </PageContainer>
  );
}
