import mountQuery from 'utils/mountQuery';

class ClientsService {

  findAll(page = 1, limit = 10, sortBy = {}, filterBy = {}) {
    return mountQuery('/clients', { page, limit, sortBy, filterBy });
  }

  findById(id) {
    return `/clients/${id}`;
  }

}

export default new ClientsService();
