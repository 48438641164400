import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select as MaterialSelectField
} from '@mui/material';

SelectField.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.array,
  readOnly: PropTypes.bool,
  required: PropTypes.bool
}

export default function SelectField({
  name,
  label,
  options,
  required,
  readOnly = false,
  ...other
}) {
  const { control } = useFormContext();

  return (
    <FormControl fullWidth>
      <InputLabel id={`${name}-label`} required={required}>
        {label}
      </InputLabel>
      <Controller
        name={name}
        id={name}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <>
            <MaterialSelectField
              {...field}
              labelId={`${name}-label`}
              fullWidth
              {...other}
              required={required}
              label={label}
              inputProps={{ readOnly }}
            >
              {!required && (
                <MenuItem value="">
                  <em>Nenhum</em>
                </MenuItem>
              )}
              {options.map(({ label, value }) => (
                <MenuItem value={value} key={`${label}-${value}`}>
                  {label}
                </MenuItem>
              ))}
            </MaterialSelectField>
            {error && <FormHelperText error>{error?.message}</FormHelperText>}
          </>
        )}
      />
    </FormControl>
  );
}
