import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { isEmpty } from 'lodash';
import { Grid } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';

import PageContainer from 'components/PageContainer';
import GridContainer from 'components/GridContainer';
import { FormProvider, TextField } from 'components/hook-form';

import { useActionSnackbar } from 'hooks/useActionSnackbar';
import DistributionCentersService from 'services/DistributionCentersService';

import DistributionCenterCreateSchema from '../utils/validations/DistributionCenterCreateValidation';

function setFormInitialValues() {
  return {
    name: '',
    document: '',
    code : '',
    address:{
      number: '',
      street: '',
      neighborhood : '',
      postalCode : '',
      city : '',
      state : '',
      coordinates:{
        latitude : '',
        longitude : ''
      }
    }
  };
}

function DistributionCenterCreate() {
  const [loading, setLoading] = useState(false);
  const { addActionSnackbar } = useActionSnackbar();
  const navigate = useNavigate();

  const methods = useForm({
    resolver: yupResolver(DistributionCenterCreateSchema),
    defaultValues: setFormInitialValues()
  });

  const {
    handleSubmit,
    formState: { isSubmitting }
  } = methods;

  const onSubmit = async data => {
    setLoading(true);

    try {
      data.typeId = 'ef1636e0-524a-4abb-a008-222f7145d0e3';
      
      const res = await DistributionCentersService.create(data);

      if (!isEmpty(res)) {
        addActionSnackbar({
          severity: 'success',
          message: 'Centro de Distribuição cadastrado com sucesso!'
        });
        navigate(`/dashboard/distribution-centers/${res.id}`);
      }
    } catch (error) {
      addActionSnackbar({
        severity: 'error',
        message: error.message,
        statusList: error.badFields
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <PageContainer title="Cadastrar Centro de Distribuição">
       <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <GridContainer>
          <Grid item xs={12}>
            <TextField 
              type="text" 
              name="name" 
              label="Nome" 
              autoComplete="off" 
              required
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              type="text"
              name="document"
              mask="cnpj"
              label="Documento"
              autoComplete="off"
              required
            />
          </Grid>

          <Grid item xs={12}>
            <TextField 
              type="text"
              name="code"
              label="Código" 
              autoComplete="off" 
              required
            />
          </Grid>

          <Grid item xs={12}>
            <TextField 
              type="text" 
              name="address.street" 
              label="Rua" 
              autoComplete="off" 
              required
            />
          </Grid>

          <Grid item xs={12}>
            <TextField 
              type="text" 
              name="address.neighborhood" 
              label="Bairro" 
              autoComplete="off" 
              required
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              type="text" 
              name="address.number" 
              label="Número" 
              autoComplete="off" 
              required
            />
          </Grid>

          <Grid item xs={12}>
            <TextField 
              type="text"
              name="address.postalCode"
              label="Código Postal"
              mask="cep" 
              autoComplete="off" 
              required
            />
          </Grid>

          <Grid item xs={12}>
            <TextField 
              type="text" 
              name="address.city" 
              label="Cidade" 
              autoComplete="off" 
              required
            />
          </Grid>

          <Grid item xs={12}>
            <TextField 
              type="text" 
              name="address.state" 
              label="Estado" 
              autoComplete="off" 
              required 
            />
          </Grid>

          <Grid item xs={12}>
            <TextField 
              type="text" 
              name="address.coordinates.latitude" 
              label="Latitude" 
              autoComplete="off" 
              required
            />
          </Grid>

          <Grid item xs={12}>
            <TextField 
              type="text" 
              name="address.coordinates.longitude" 
              label="Longitude" 
              autoComplete="off" 
              required 
            />
          </Grid>

          <Grid item xs={12}>
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting || loading}
            >
              Cadastrar
            </LoadingButton>
          </Grid>

        </GridContainer>
      </FormProvider>
    </PageContainer>
  );
}

export default DistributionCenterCreate;
