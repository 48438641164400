import HttpClient from 'utils/HttpClient';
import mountQuery from 'utils/mountQuery';

class ProductsService {
  findAll(page = 1, limit = 10, sortBy = {}, filterBy = {}) {
    return mountQuery('/products', { page, limit, sortBy, filterBy });
  }

  findById(id) {
    return `/products/${id}`;
  }

  findByDistributionCenter(id, page = 1, limit = 10, sortBy = {}, filterBy = {} ) {
    return mountQuery(`/products/by/distribution-center/${id}`,{ 
      page,
      limit,
      sortBy,
      filterBy
    });
  }

  async create(data) {
    return HttpClient.post('/products', data);
  }

  async update(id, data) {
    return HttpClient.put(`/products`, id, { 
      name: data.name,
      description: data.description,
      price: data.price,
      stock: data.stock,
      unit: data.unit,
      toHighlight: data.toHighlight,
      variantOf: data.variantOf
    });
  }

  async delete(id) {
    return HttpClient.delete(`/products`, id);
  }
}

export default new ProductsService();
