import React from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

const propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onAccept: PropTypes.func,
  removeActionDialog: PropTypes.func.isRequired
};

function ActionDialog({
  message,
  title,
  isOpen = true,
  onClose = () => {},
  onAccept = () => {},
  removeActionDialog
}) {
  const [open, setOpen] = React.useState(isOpen);

  const handleClose = () => {
    setOpen(false);
    onClose();
    removeActionDialog();
  }

  const handleAccept = () => {
    onAccept();
    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Não</Button>
        <Button onClick={handleAccept} autoFocus>Sim</Button>
      </DialogActions>
    </Dialog>
  );
}

ActionDialog.propTypes = propTypes;

export default React.memo(ActionDialog);
