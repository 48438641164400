import { replace } from 'lodash';
import numeral from 'numeral';

export function fNumber(number) {
  return numeral(number).format();
}

export function fShortenNumber(number) {
  return replace(numeral(number).format('0.00'), '.00', '');
}
