export const TABLE_HEAD = [
  { id: 'name', label: 'Nome', alignRight: false, orderable: true },
  { id: 'code', label: 'Código', alignRight: false, orderable: false },
];

export const formatTableData = (data) => ({
  key: data?.id,
  columns: [
    { render: data?.name }, 
    { render: data?.code }
  ]
});
