import { useTheme } from '@mui/material/styles';
import { Container, Typography } from '@mui/material';
import Page from 'components/Page';

export default function DashboardApp() {
  const theme = useTheme();

  return (
    <Page title="Dashboard">
      <Container maxWidth="xl">
        <Typography variant="h4">
          Desculpe, estamos trabalhando nisso...
        </Typography>
        <Typography variant="p" sx={{ mb: 5 }} color="gray">
          Enquanto isso, utilize as outras funções de administração do sistema.
        </Typography>
      </Container>
    </Page>
  );
}
