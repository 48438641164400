import { addDays, isToday, nextMonday, startOfWeek , previousMonday, getISOWeek, format, isPast, endOfWeek } from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";

const WEEK_STARTS_ON = 0; // DOMINGO
const WEEK_DAYS_NAMES = ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'];

export const getDate = (date) => zonedTimeToUtc(date, 'America/Fortaleza');

export const getWeekDays = (date) => {
  const days = [];

  let currentWeekDate = getStartOfWeek(date);
  const number = getISOWeek(currentWeekDate, { weekStartsOn: WEEK_STARTS_ON });

  WEEK_DAYS_NAMES.forEach(name => {
    days.push({
      date: currentWeekDate,
      formattedDate: format(currentWeekDate, 'dd/MM/yyyy'),
      isToday: isToday(currentWeekDate),
      isPast: isPast(currentWeekDate) && !isToday(currentWeekDate),
      name
    });

    currentWeekDate = addDays(currentWeekDate, 1);
  });

  return { number, days };
};

export const getStartOfWeek = (date) => startOfWeek(date, { weekStartsOn: WEEK_STARTS_ON });

export const getEndOfWeek = (date) => endOfWeek(date, { weekStartsOn: WEEK_STARTS_ON });

export const getPreviousWeekStartDate = (date) => previousMonday(date);

export const getNextWeekStartDate = (date) => nextMonday(date);

export const getWeekNumber = () => {

};
