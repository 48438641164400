import HttpClient from 'utils/HttpClient';
import mountQuery from 'utils/mountQuery';

class DiscountsService {
  findAll(page = 1, limit = 10, sortBy = {}, filterBy = {}) {
    return mountQuery('/product-discounts', { page, limit, sortBy, filterBy });
  }

  findById(id) {
    return `/products-discounts/${id}`;
  }

  findByProduct(id, page = 1, limit = 10, sortBy = {}, filterBy = {} ) {
    return mountQuery(`/products-discounts/by/product/${id}`,{ 
      page,
      limit,
      sortBy,
      filterBy 
    });
  }

  async create(data) {
    return HttpClient.post('/products-discounts', data);
  }

  async update(id, data) {
    return HttpClient.put(`/products-discounts`, id, { 
      value: data.discountValue,
      minimumQuantity: data.discountMinQuantity,
      maximumQuantity: data.discountMaxQuantity,
      expiresAt: data.discountExpiresAt,
    });
  }

  async delete(id) {
    return HttpClient.delete(`/products-discounts`, id);
  }
}

export default new DiscountsService();
