import { useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Grid } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';

import PageContainer from 'components/PageContainer';
import GridContainer from 'components/GridContainer';
import { FileField, FormProvider, SelectField } from 'components/hook-form';

import { useActionSnackbar } from 'hooks/useActionSnackbar';
import CategoriesImagesService from 'services/CategoriesImagesService';

import dataToFormData from 'utils/dataToFormData';
import { isEmpty } from 'lodash';
import CategoriesImagesCreateSchema from '../utils/validations/CategoriesImagesCreateValidation';

function setFormInitialValues() {
  return {
    type: ''
  };
}

function CategoriesImagesCreate() {
  const [loading, setLoading] = useState(false);
  const inputRef = useRef(null);
  
  const { categoryId, distributionCenterId } = useParams();
  const navigate = useNavigate();
  
  const { addActionSnackbar } = useActionSnackbar();

  const methods = useForm({
    resolver: yupResolver(CategoriesImagesCreateSchema),
    defaultValues: setFormInitialValues()
  });

  const {
    handleSubmit,
    formState: { isSubmitting }
  } = methods;

  const onSubmit = async data => {
    setLoading(true);

    try {
      data.categoryId = categoryId;

      await inputRef.current.hasError();

      const inputFile = inputRef.current.getFiles();

      const formData = dataToFormData(data);
      formData.append('file', inputFile[0]);

      const res = await CategoriesImagesService.create(formData);

      if (!isEmpty(res)) {
        addActionSnackbar({
          severity: 'success',
          message: 'Imagem para a categoria cadastrada com sucesso!'
        });

        navigate(`/dashboard/distribution-centers/${distributionCenterId}/categories/${categoryId}/`);
      }
    } catch (error) {
      addActionSnackbar({
        severity: 'error',
        message: error.message,
        statusList: error?.badFields
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <PageContainer title="Cadastrar Imagem para Categoria">
       <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <GridContainer>
          <Grid item xs={12}>
            <SelectField
              name="type"
              label="Tipo de imagem"
              options={[
                { label: 'ICON', value: 'ICON' },
                { label: 'COVER', value: 'COVER' }
              ]}
              required
            />
          </Grid>

          <Grid item xs={12}>
            <FileField
              name="images"
              ref={inputRef}
              label="Selecione a Imagem"
              min={1}
              max={1}
              extensions={['image/*']}
            />
          </Grid>

          <Grid item xs={12}>
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting || loading}
            >
              Cadastrar
            </LoadingButton>
          </Grid>

        </GridContainer>
      </FormProvider>
    </PageContainer>
  );
}

export default CategoriesImagesCreate;
