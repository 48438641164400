import { fDateTimeS } from "utils/formatTime";

export const TABLE_HEAD = [
  { id: 'name', label: 'Categoria', alignRight: false, orderable: true },
  { id: 'enabled', label: 'Habilitado', orderable: true }
];

export const formatTableData = (data) => ({
  key: data?.id,
  columns: [
    { render: data?.name },
    { render: data?.enabled ? 'Habilitado' : 'Não Habilitado' }
  ]
});

export const formatGalleryData = (data) => ({
  id: data?.id,
  src: data?.url,
  title: data?.type,
  subtitle: `Criado em: ${fDateTimeS(data?.createdAt)}`
});
