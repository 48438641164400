export const DELIVERY_STATUS = {
  PENDING: {
    name: 'PENDENTE',
    color: '#fff'
  },
  SCHEDULED: {
    name: 'AGENDADO',
    color: '#89c8f0'
  },
  PROCESSING: {
    name: 'PROCESSANDO',
    color: '#F0E689'
  },
  'ON ROUTE': {
    name: 'EM ROTA',
    color: '#ccf089'
  },
  CONCLUDED: {
    name: 'CONCLUÍDO',
    color: '#90E57A'
  },
  CANCELED: {
    name: 'CANCELADO',
    color: '#E5726D'
  }
}
