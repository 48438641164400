import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
import { T, cond, isEqual } from 'lodash/fp';
import {
  IconButton,
  InputAdornment,
  TextField as MaterialTextField
} from '@mui/material';
import { useState, useMemo } from 'react';
import InputMask from 'react-input-mask';
import Iconify from 'components/Iconify';
import { isEmpty } from 'lodash';

TextField.propTypes = {
  name: PropTypes.string,
  mask: PropTypes.string,
  readOnly: PropTypes.bool
};

export default function TextField({
  name,
  mask = '',
  readOnly = false,
  ...other
}) {
  const { control } = useFormContext();
  const [showPassword, setShowPassword] = useState(false);

  const handleMask = useMemo(
    () =>
      cond([
        [isEqual('cpf'), () => '999.999.999-99'],
        [isEqual('phone'), () => '(99) \\9 9999-9999'],
        [isEqual('cnpj'), () => '99.999.999/9999-99'],
        [isEqual('cep'), () => '99999-999'],
        [T, () => mask]
      ])(mask),
    [mask]
  );

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        let { type } = other;
        let inputProps = { readOnly };

        if (type === 'password') {
          inputProps = {
            ...inputProps,
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  <Iconify
                    icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'}
                  />
                </IconButton>
              </InputAdornment>
            )
          };

          if (showPassword) {
            type = 'text';
          }
        }

        if (isEmpty(mask)) {
          return (
            <MaterialTextField
              {...field}
              fullWidth
              value={
                typeof field.value === 'number' && field.value === 0
                  ? ''
                  : field.value
              }
              error={!!error}
              helperText={error?.message}
              {...other}
              type={type}
              InputProps={inputProps}
            />
          );
        }

        return (
          <InputMask
            {...field}
            mask={handleMask}
            maskChar=""
            value={
              typeof field.value === 'number' && field.value === 0
                ? ''
                : field.value
            }
            onChange={field.onChange}
          >
            {inputMaskProps => (
              <MaterialTextField
                fullWidth
                error={!!error}
                helperText={error?.message}
                {...other}
                type={type}
                InputProps={{ ...inputProps, ...inputMaskProps }}
              />
            )}
          </InputMask>
        );
      }}
    />
  );
}
