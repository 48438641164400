import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Grid,
  TableContainer,
  Typography,
  Table as MaterialTable,
  TableBody,
  TableRow,
  Divider
} from '@mui/material';

import Loader from 'components/Loader';
import Scrollbar from 'components/Scrollbar';
import PageContainer from 'components/PageContainer';
import useFetcher from 'hooks/useFetcher';
import OperatingDaysService from 'services/OperatingDaysService';
import Iconify from 'components/Iconify';
import Error from 'components/Error';
import { format } from 'date-fns';
import { isEmpty } from 'lodash';
import {
  getEndOfWeek,
  getNextWeekStartDate,
  getPreviousWeekStartDate,
  getStartOfWeek,
  getWeekDays
} from '../utils/utils';
import Cell from '../components/Cell';
import FormOperation from '../components/FormOperation';

export default function OperationDaysList() {
  const [date, setDate] = useState(new Date());
  const [week, setWeek] = useState({});

  const { distributionCenterId } = useParams();

  const { data, loading, error, mutate } = useFetcher(
    OperatingDaysService.findByDistributionCenter(distributionCenterId, {
      filterBy: {
        dateStart: getStartOfWeek(date),
        dateFinish: getEndOfWeek(date)
      }
    })
  );

  useEffect(() => {
    setWeek(getWeekDays(date));
  }, [date]);

  const goToPrevWeek = () => {
    setDate(getPreviousWeekStartDate(date));
  };

  const goToNextWeek = () => {
    setDate(getNextWeekStartDate(date));
  };

  const getData = d => {
    const dt = format(d, 'yyyy-MM-dd');

    return data?.items?.find(item => item?.data?.split('T')[0] === dt) || {};
  };

  if (error) return <Error {...{ error }} />;

  return (
    <PageContainer title="Dias de operação">
      {loading ? (
        <Loader />
      ) : (
        <>
          <Grid container sx={{ mb: 2 }}>
            <Grid
              item
              xs={2}
              alignContent="center"
              justifyContent="center"
              sx={{ display: 'flex', cursor: 'pointer' }}
              onClick={() => goToPrevWeek()}
              role="button"
            >
              <Iconify icon="ic:round-chevron-left" width={32} height={32} />
            </Grid>
            <Grid item xs={8}>
              <Typography variant="h6" align="center">
                Semana {week?.number ?? 0}:
              </Typography>
            </Grid>
            <Grid
              item
              xs={2}
              alignContent="center"
              justifyContent="center"
              sx={{ display: 'flex', cursor: 'pointer' }}
              onClick={() => goToNextWeek()}
              role="button"
            >
              <Iconify icon="ic:round-chevron-right" width={32} height={32} />
            </Grid>
          </Grid>
          <Divider />
          <Grid container>
            <Scrollbar>
              <TableContainer sx={{ minWidth: 1000 }}>
                <MaterialTable>
                  <TableBody>
                    {week?.days?.map((day, i) => (
                      <TableRow
                        selected={day.isToday}
                        key={String(Math.random() + i)}
                      >
                        <Cell
                          isToday={day.isToday}
                          isPast={day.isPast}
                          hasData={!isEmpty(getData(day.date))}
                        >
                          <span>
                            {`${day.name} (${day.formattedDate})`}
                            {isEmpty(getData(day.date)) ? '*' : ''}
                          </span>
                        </Cell>
                        <Cell isToday={day.isToday} isPast={day.isPast}>
                          <FormOperation
                            date={day.date}
                            isPast={day.isPast}
                            data={getData(day.date)}
                            mutate={mutate}
                          />
                        </Cell>
                      </TableRow>
                    ))}
                  </TableBody>
                </MaterialTable>
              </TableContainer>
            </Scrollbar>
          </Grid>
        </>
      )}
    </PageContainer>
  );
}
