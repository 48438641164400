import { Typography } from '@mui/material';

import PageContainerCentered from 'components/PageContainerCentered';
import LoginForm from '../components/LoginForm';

export default function Login() {
  return (
    <PageContainerCentered title="Painel Administrativo - Entrar">
      <Typography sx={{ color: 'text.secondary', mb: 5 }} align="center">
        Faça login inserindo seus dados abaixo:
      </Typography>

      <LoginForm />
    </PageContainerCentered>
  );
}
