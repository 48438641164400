import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import useAuth from 'hooks/useAuth';
import { FormProvider, TextField } from 'components/hook-form';
import { useActionSnackbar } from 'hooks/useActionSnackbar';
import loginSchema from '../../utils/validations/loginValidation';

function setFormInitialValues() {
  return {
    email: '',
    password: ''
  };
}

export default function LoginForm() {
  const { loading, signin } = useAuth();
  const { addActionSnackbar } = useActionSnackbar();

  const methods = useForm({
    resolver: yupResolver(loginSchema),
    defaultValues: setFormInitialValues()
  });

  const {
    handleSubmit,
    formState: { isSubmitting }
  } = methods;

  const onSubmit = async ({ email, password }) => {
    try {
      await signin(email, password);
    } catch (error) {
      addActionSnackbar({
        severity: 'error',
        message: error.message,
        statusList: error.badFields
      });
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <TextField type="email" name="email" label="E-mail" />
        <TextField type="password" name="password" label="Senha" />
        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting || loading}
        >
          Entrar
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
}
