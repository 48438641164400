import * as Yup from 'yup';

export default Yup.object().shape({
  name: Yup.string().label('Nome').required(),
  description: Yup.string().label('Descrição').required(),
  price: Yup.number().label('Preço').required(),
  stock: Yup.number().label('Estoque').required(),
  unit: Yup.number().label('Unidade').required(),
  brandId: Yup.string().label('Marca').required(),
  categoryId: Yup.string().label('Categoria').required(),
  unitType: Yup.string().label('Tipo da unidade').oneOf(['kg','g','mg','un','ml','l']).required(),
  variantOf: Yup.string().label('Variante de').nullable()
});
