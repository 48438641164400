export const TABLE_HEAD = [
  { id: 'name', label: 'Nome', alignRight: false, orderable: true },
  { id: 'email', label: 'E-mail', alignRight: false, orderable: false },
  { id: 'phone', label: 'Telefone', alignRight: false, orderable: false },
  { id: 'created', label: 'Criado em', alignRight: false, orderable: false },
];

export const formatTableData = (data) => ({
  key: data?.id,
  columns: [
    { render: data?.name }, 
    { render: data?.email },
    { render: data?.phone },
    { render: data?.createdAt },
  ]
});
