import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useActionDialog } from 'hooks/useActionDialog';
import { useConfirmAccess } from 'hooks/useConfirmAccess';
import { TextField, FormProvider } from 'components/hook-form';
import { Grid, Typography } from '@mui/material';
import { fDateTimeS } from 'utils/formatTime';
import { isEmpty } from 'lodash';
import { useActionSnackbar } from 'hooks/useActionSnackbar';
import useFetcher from 'hooks/useFetcher';
import GridContainer from 'components/GridContainer';
import Error from 'components/Error';
import Loader from 'components/Loader';
import PageContainer from 'components/PageContainer';
import EmployeeService from 'services/EmployeeService';
import { LoadingButton } from '@mui/lab';
import { yupResolver } from '@hookform/resolvers/yup';
import EmployeeSendCodeValidationSchema from '../utils/validations/EmployeeSendCodeValidation';

function EmployeePage() {
  const methods = useForm({
    resolver: yupResolver(EmployeeSendCodeValidationSchema)
  });
  const navigate = useNavigate();
  const { addActionSnackbar } = useActionSnackbar();
  const { addConfirmAccess } = useConfirmAccess();
  const { employeeId } = useParams();
  const { addActionDialog } = useActionDialog();
  const { data, loading, error, mutate } = useFetcher(
    EmployeeService.findById(employeeId)
  );

  const {
    handleSubmit,
    formState: { isSubmitting }
  } = methods;

  function onAccept() {
    handleDelete(data?.id);
  }

  function handleConfirmDelete() {
    addActionDialog({
      message: 'Deseja realmente deletar este funcionário?',
      title: 'Confirmação de exclusão',
      onAccept
    });
  }

  function handleUpdate(id) {
    navigate(`/dashboard/employee/${id}/update`);
  }

  const handleSendCode = async () => {
    try {
      const res = await EmployeeService.sendCodeViaEmail(employeeId);

      if (!isEmpty(res)) {
        addActionSnackbar({
          severity: 'success',
          message: 'E-mail com código de confirmação enviado com sucesso!'
        });
      }
    } catch (error) {
      addActionSnackbar({
        severity: 'error',
        message: error.message,
        statusList: error.badFields
      });
    }
  };

  const handleDelete = async id => {
    const password = await addConfirmAccess();

    try {
      const res = await EmployeeService.delete(id, password);

      if (!isEmpty(res)) {
        addActionSnackbar({
          severity: 'success',
          message: 'Funcionário deletado com sucesso!'
        });
        navigate('/dashboard/employee');
      }
    } catch (error) {
      addActionSnackbar({
        severity: 'error',
        message: error.message,
        statusList: error.badFields
      });
    }
  };

  const onConfirmAccount = async data => {
    try {
      const { code } = data;

      const res = await EmployeeService.confirmAccount(employeeId, { code });

      if (!isEmpty(res)) {
        addActionSnackbar({
          severity: 'success',
          message: 'Funcionário verificado com sucesso!'
        });

        mutate();
      }
    } catch (error) {
      addActionSnackbar({
        severity: 'error',
        message: error.message,
        statusList: error.badFields
      });
    }
  };

  useEffect(() => {
    methods.reset({
      name: data?.name || '',
      document: data?.document || '',
      phone: data?.phone || '',
      email: data?.email || '',
      type: data?.type.name || '',
      created: data?.createdAt ? fDateTimeS(data?.createdAt) : '',
      verified: data?.verifiedAccount ? 'Verificado' : 'Não Verificado',
      code: ''
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (error) return <Error {...{ error }} />;

  return (
    <PageContainer
      title="Painel do Funcionário"
      menuOptions={{
        editable: () => handleUpdate(data?.id),
        deletable: () => handleConfirmDelete(data?.id),
        options: !data?.verifiedAccount
          ? [
              {
                label: 'Reenviar código',
                icon: 'ic:baseline-email',
                action: () => handleSendCode()
              }
            ]
          : []
      }}
    >
      {loading ? (
        <Loader />
      ) : (
        <FormProvider methods={methods} onSubmit={handleSubmit(onConfirmAccount)}>
          <GridContainer>
            <Grid item xs={12}>
              <TextField type="text" name="name" label="Nome" readOnly />
            </Grid>

            <Grid item xs={12}>
              <TextField
                type="text"
                name="document"
                label="Documento"
                mask="cpf"
                readOnly
              />
            </Grid>

            <Grid item xs={12}>
              <TextField type="tel" mask="phone" name="phone" label="Telefone" readOnly />
            </Grid>

            <Grid item xs={12}>
              <TextField type="email" name="email" label="E-mail" readOnly />
            </Grid>

            <Grid item xs={12}>
              <TextField type="text" name="type" label="Tipo" readOnly />
            </Grid>

            <Grid item xs={12}>
              <TextField
                type="text"
                name="created"
                label="Data Cadastral"
                readOnly
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                type="text"
                name="verified"
                label="Verificação"
                readOnly
              />
            </Grid>

            {!data?.verifiedAccount && (
              <>
                <Grid item xs={12}>
                  <Typography variant="h6" paragraph sx={{ marginBottom: 0 }}>
                    Ativar conta:
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={10}>
                  <TextField
                    type="text"
                    name="code"
                    mask="999999"
                    label="Código de verificação"
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <LoadingButton
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    sx={{ height: '56px' }}
                    loading={isSubmitting}
                  >
                    Ativar
                  </LoadingButton>
                </Grid>
              </>
            )}
          </GridContainer>
        </FormProvider>
      )}
    </PageContainer>
  );
}

export default EmployeePage;
