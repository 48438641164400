import PropTypes from 'prop-types';
import { Box, TableRow, TableCell, TableHead as MaterialTableHead, TableSortLabel } from '@mui/material';

const visuallyHidden = {
  border: 0,
  margin: -1,
  padding: 0,
  width: '1px',
  height: '1px',
  overflow: 'hidden',
  position: 'absolute',
  whiteSpace: 'nowrap',
  clip: 'rect(0 0 0 0)',
};

TableHead.propTypes = {
  order: PropTypes.oneOf(['ASC', 'DESC']),
  orderBy: PropTypes.string,
  headLabel: PropTypes.array,
  onRequestSort: PropTypes.func,
};

export default function TableHead({
  order,
  orderBy,
  headLabel,
  onRequestSort,
}) {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <MaterialTableHead>
      <TableRow>
        {headLabel.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.alignRight ? 'right' : 'left'}
            sortDirection={orderBy === headCell.id ? order.toLowerCase() : false}            
            {
              ...(headCell?.orderable && ({
                onClick: createSortHandler(headCell.id),
                sx: { cursor: 'pointer' }
              }))
            }
          >
            <TableSortLabel
              hideSortIcon
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order.toLowerCase() : 'asc'}
              {
                ...(!headCell?.orderable && ({
                  sx: { cursor: 'default' }
                }))
              }
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box sx={{ ...visuallyHidden }}>{order.toLowerCase() === 'desc' ? 'sorted descending' : 'sorted ascending'}</Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell align="center">
          Opções
        </TableCell>
      </TableRow>
    </MaterialTableHead>
  );
}
