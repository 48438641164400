import { useParams, useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';

import Error from 'components/Error';
import Iconify from 'components/Iconify';
import Table from 'components/table/Table';
import PageContainer from 'components/PageContainer';
import BrandsService from 'services/BrandsService';

import useFetcher from 'hooks/useFetcher';
import useTable from 'hooks/useTable';

import { formatTableData, TABLE_HEAD } from '../utils/table';

export default function Brands() {

  const {
    page,
    order,
    orderBy,
    filterName,
    filterSearch,
    rowsPerPage,
    handleRequestSort,
    handleChangePage,
    handleChangeRowsPerPage,
    handleFilterByName
  } = useTable();

  
  const { distributionCenterId } = useParams();
  const navigate = useNavigate();

  const { data, loading, error } = useFetcher(
    BrandsService.findByDistributionCenter(
      distributionCenterId,
      page,
      rowsPerPage,
      { [orderBy]: order },
      { search: filterSearch }
    )
  );


  function handleCreate(){
    navigate(`/dashboard/distribution-centers/${distributionCenterId}/brands/create`);
  }

  if (error) return <Error {...{ error }} />;

  return (
    <PageContainer
      title="Painel de Marcas"
      headerComponent={
        <Button
          onClick={() => handleCreate()}
          variant="contained"
          to="#"
          startIcon={<Iconify icon="eva:plus-fill" />}
        >
          Nova Marca
        </Button>
      }
    >
      <Table
        data={data}
        loading={loading}
        tableHead={TABLE_HEAD}
        order={order}
        orderBy={orderBy}
        rowsPerPage={rowsPerPage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        filterName={filterName}
        onFilterName={handleFilterByName}
        onRequestSort={handleRequestSort}
        formatTableData={formatTableData}
        to={`/dashboard/distribution-centers/${distributionCenterId}/brands/`}
      />
    </PageContainer>
  );
}