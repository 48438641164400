import * as Yup from 'yup';

export default Yup.object().shape({
  status: Yup.string().label('Status').oneOf(['PENDING','SCHEDULED','PROCESSING','ON ROUTE','CONCLUDED','CANCELED','']),

  dateStart: Yup.string()
  .when('dateFinish', {
    is: ( val ) => val !== undefined,
    then: Yup.string().required('Informe a data inicial')
  }),
  dateFinish: Yup.string()
    .when('dateStart', {
      is: ( val ) => val !== undefined,
      then: Yup.string().required('Informe a data final')
    })
},[['dateFinish', 'dateStart']]);
