import { useMemo } from 'react';
import {
  TableBody,
  TableCell,
  Table as MaterialTable,
  TableContainer,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

import Scrollbar from 'components/Scrollbar';
import SearchNotFound from 'components/SearchNotFound';
import Loader from 'components/Loader';

import { useNavigate } from 'react-router-dom';
import TableHead from './TableHead';
import TableMoreMenu from './TableMoreMenu';
import TableToolbar from './TableToolbar';

import { renderColumns } from './utils';

Table.propTypes = {
  order: PropTypes.string,
  orderBy: PropTypes.string,
  tableHead: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      alignRight: PropTypes.bool,
      orderable: PropTypes.bool
    })
  ),
  rowCount: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
  onRequestSort: PropTypes.func,
  rowsPerPage: PropTypes.number,
  page: PropTypes.number,
  onChangePage: PropTypes.func,
  onChangeRowsPerPage: PropTypes.func,
  data: PropTypes.object,
  formatTableData: PropTypes.func,
  to: PropTypes.string,
  loading: PropTypes.bool,
  isPaginable: PropTypes.bool,
  isSearchable: PropTypes.bool,
  isFilterable: PropTypes.bool
};

export default function Table({
  order = 'ASC',
  orderBy = '',
  tableHead,
  rowCount,
  filterName,
  onFilterName,
  onRequestSort,
  rowsPerPage,
  page,
  onChangePage,
  onChangeRowsPerPage,
  data,
  formatTableData = () => {},
  to = '',
  loading = false,
  isPaginable = true,
  isSearchable = true,
  isFilterable = true
}) {
  const navigate = useNavigate();

  const formattedData = useMemo(
    () =>
      !loading && !isEmpty(data?.items)
        ? data?.items?.map(formatTableData)
        : [],
    [data, loading, formatTableData]
  );

  const columnNumber = useMemo(() => (tableHead?.length || 0) + 1, [tableHead]);

  const handleRowClick = key => {
    if (!isEmpty(to) && !isEmpty(key)) {
      navigate(`${to}${key}`);
    }
  };

  return (
    <>
      <TableToolbar
        filterName={filterName}
        onFilterName={onFilterName}
        isSearchable={isSearchable}
        isFilterable={isFilterable}
      />
      <Scrollbar>
        <TableContainer sx={{ minWidth: 800 }}>
          <MaterialTable>
            <TableHead
              order={order}
              orderBy={orderBy}
              headLabel={tableHead}
              onRequestSort={onRequestSort}
            />
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell
                    align="center"
                    colSpan={columnNumber}
                    sx={{ py: 3 }}
                  >
                    <Loader />
                  </TableCell>
                </TableRow>
              ) : (
                <>
                  {isEmpty(formattedData) ? (
                    <TableRow>
                      <TableCell
                        align="center"
                        colSpan={columnNumber}
                        sx={{ py: 3 }}
                      >
                        {filterName ? (
                          <SearchNotFound searchQuery={filterName} />
                        ) : (
                          <Typography
                            gutterBottom
                            align="center"
                            variant="subtitle1"
                          >
                            Sem resultados.
                          </Typography>
                        )}
                      </TableCell>
                    </TableRow>
                  ) : (
                    formattedData?.map(({ key, columns }) => (
                      <TableRow
                        hover
                        key={String(key)}
                        onClick={() => handleRowClick(key)}
                        sx={{ cursor: 'pointer' }}
                      >
                        {renderColumns(columns)}
                        <TableCell align="center">
                          <TableMoreMenu />
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </>
              )}
            </TableBody>
          </MaterialTable>
        </TableContainer>
      </Scrollbar>

      {!isEmpty(formattedData) && isPaginable && (
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50]}
          component="div"
          count={rowCount || data?.metadata?.totalItems}
          rowsPerPage={rowsPerPage}
          page={page - 1}
          onPageChange={onChangePage}
          onRowsPerPageChange={onChangeRowsPerPage}
          labelRowsPerPage="Registros por página"
          labelDisplayedRows={({ from, to, count }) =>
            `${from} - ${to} de ${count !== -1 ? count : `mais de ${to}`}`
          }
        />
      )}
    </>
  );
}
