export const TABLE_HEAD = [
  { id: 'code', label: 'Code', alignRight: false, orderable: true },
  { id: 'status', label: 'Status', alignRight: false, orderable: false },
  { id: 'subTotal', label: 'Valor', alignRight: false, orderable: false },
  { id: 'created', label: 'Criado em', alignRight: false, orderable: false },
];

export const formatTableData = (data) => ({
  key: data?.id,
  columns: [
    { render: data?.code }, 
    { render: data?.status },
    { render: data?.subTotal },
    { render: data?.createdAt },
  ]
});
