import HttpClient from 'utils/HttpClient';

class CategoriesImagesService {
  constructor() {
    this.url = '/files/categories/images';
  }
  
  async create(data) {
    return HttpClient.post(this.url, data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }

  async delete(id) {
    return HttpClient.delete(this.url, id);
  }
}

export default new CategoriesImagesService();
