import { isEmpty } from 'lodash';
import { useState, useEffect } from 'react';
import AuthService from 'services/AuthService';

import { AUTH_USER_STORAGE } from 'utils/constants';

function useAuth() {
  const [user, setUser] = useState({});
  const [authenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const userData = localStorage.getItem(AUTH_USER_STORAGE);

    if (!isEmpty(userData)) {
      setUser(JSON.parse(userData));
      setAuthenticated(true);
    }

    setLoading(false);
  }, []);

  async function signin(email, password) {
    return AuthService.signin({ email, password }).then(response => {
      if (!isEmpty(response.accessToken)) {
        const {
          employee: { id, name, verifiedAccount, email, type },
          accessToken
        } = response;

        const user = {
          id,
          name,
          verifiedAccount,
          type,
          accessToken,
          email
        };

        (async () => localStorage.setItem(AUTH_USER_STORAGE, JSON.stringify(user)))();
        setAuthenticated(true);
        window.location.href = '/dashboard/app';
      }
      return { data: response };
    });
  }

  function signout() {
    localStorage.removeItem(AUTH_USER_STORAGE);

    setAuthenticated(false);
  }

  return { user, authenticated, loading, signin, signout };
}

export default useAuth;
