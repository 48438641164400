import { useParams, useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';

import Error from 'components/Error';
import Iconify from 'components/Iconify';
import Table from 'components/table/Table';
import PageContainer from 'components/PageContainer';
import TypesPaymentsService from 'services/TypesPaymentsService';

import useFetcher from 'hooks/useFetcher';
import useTable from 'hooks/useTable';

import { formatTableData, TABLE_HEAD } from '../utils/table';

export default function TypesPayments() {
  const {
    page,
    order,
    orderBy,
    filterName,
    filterSearch,
    rowsPerPage,
    handleRequestSort,
    handleChangePage,
    handleChangeRowsPerPage,
    handleFilterByName
  } = useTable();

  const { distributionCenterId } = useParams();
  const navigate = useNavigate();

  const { data, loading, error } = useFetcher(
    TypesPaymentsService.findByDistributionCenter(
      distributionCenterId,
      page,
      rowsPerPage,
      { [orderBy]: order },
      { search: filterSearch }
    )
  );

  function handleCreate() {
    navigate(
      `/dashboard/distribution-centers/${distributionCenterId}/types-payments/create`
    );
  }

  if (error) return <Error {...{ error }} />;

  return (
    <PageContainer
      title="Painel de Tipos de Pagamento"
      headerComponent={
        <Button
          onClick={() => handleCreate()}
          variant="contained"
          to="#"
          startIcon={<Iconify icon="eva:plus-fill" />}
        >
          Novo tipo de pagamento
        </Button>
      }
    >
      <Table
        data={data}
        loading={loading}
        tableHead={TABLE_HEAD}
        order={order}
        orderBy={orderBy}
        rowsPerPage={rowsPerPage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        filterName={filterName}
        onFilterName={handleFilterByName}
        onRequestSort={handleRequestSort}
        formatTableData={formatTableData}
        to={`/dashboard/distribution-centers/${distributionCenterId}/types-payments/`}
      />
    </PageContainer>
  );
}
