import axios from 'axios';
import { isEmpty } from 'lodash';
import { AUTH_USER_STORAGE } from './constants';

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL
});

api.interceptors.request.use(config => {
  const user = localStorage.getItem(AUTH_USER_STORAGE);

  if (!isEmpty(user)) {
    const { accessToken } = JSON.parse(user);
    
    config.headers = {
      Authorization: accessToken ? `Bearer ${accessToken}` : ''
    };
  }

  return config;
});

export default api;
