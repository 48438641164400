import { Box, Button, Card, CardContent, Divider, FormControl, Grid, InputLabel, MenuItem, Modal, Select, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';

import OrderService from 'services/OrderService';
import { fDateTimeS } from 'utils/formatTime';
import { useActionSnackbar } from 'hooks/useActionSnackbar';

import { DELIVERY_STATUS } from '../utils/constants';

export default function OrderCard({ pedido }) {
  const { addActionSnackbar } = useActionSnackbar();
  const [status, setStatus] = useState('');
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setStatus(pedido?.status);
  }, [pedido]);

  const backgroundColor = useMemo(() => DELIVERY_STATUS[status]?.color ?? "#fff", [status]);

  const clientData = pedido?.client ?? pedido?.user;

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleStatus = (e) => {
    setStatus(e.target.value);
    OrderService.sendStatus(e.target.value, pedido.id);
  }

  const handleCopy = () => {
    const textProducts = pedido.orderProducts.map((item) => `Nome: ${item.product.name}
Quantidade: ${item.quantity}
Preço: R$ ${item.product.price}
Sub Total: R$ ${item.subTotal}`).join('\n-------------\n');

    const text = `- Cliente: -
Nome: ${clientData?.name}
Email: ${clientData?.email}
Telefone: ${clientData?.phone}

- Pedido: -
Code: ${pedido.code}
Status: ${DELIVERY_STATUS[pedido.status].name}
Valor da entrega: R$ ${pedido.deliveryFee}
Valor total: R$ ${pedido.subTotal}
Tipo de pagamento: ${pedido.typePayment.name}
Troco para: R$ ${Number(pedido.change ?? 0).toFixed(2)}
${pedido.change ? `Troco: R$ ${(Number(pedido.change ?? 0) - Number(pedido.subTotal)).toFixed(2)}` : ''}
Observação: ${pedido.observation ?? 'Nenhuma observação'}

- Endereço: -
Cidade: ${pedido?.orderAddress?.city}
Estado: ${pedido?.orderAddress?.state}
Rua: ${pedido?.orderAddress?.street}
Bairro: ${pedido?.orderAddress?.neighborhood}
Número: ${pedido?.orderAddress?.number}
Complemento: ${pedido?.orderAddress?.complement}

- Produtos: -
${textProducts}
`;

    navigator.clipboard.writeText(text);

    addActionSnackbar({
      severity: 'success',
      message: 'Informações do pedido copiadas.'
    });
  };

  const isOptionDisabled = (index) => {
    const statusList = Object.keys(DELIVERY_STATUS);

    // if (
    //   status === 'SCHEDULED' &&
    //   pedido?.schedules?.schedules &&
    //   (!isSameHour(new Date(), new Date(pedido.schedules.schedules) ||
    //  !isPast(new Date(pedido.schedules.schedules))))
    // ) {
    //   return index < statusList.length - 1;
    // }

    const key = statusList.findIndex(item => item === status);

    if (
      typeof key === 'undefined' ||
      statusList[index] === 'SCHEDULED'
    ) {
      return true;
    }

    return key > index;
  };

  const convertedDate = () => fDateTimeS(pedido?.createdAt ?? new Date());

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
    p: 4,
    maxHeight: '550px',
    overflowY: 'scroll',
    maxWidth: '90%'
  };

  return (
    <Grid item xl={4} sm={6} sx={{ width: '100%' }}>
      <Card sx={{ width: '100%', backgroundColor }}>
        <CardContent>
          CODE: <span style={{ fontWeight: 'bold' }}>{pedido.code}</span><br />
          DATA: <span style={{ fontWeight: 'bold' }}>{convertedDate()}</span><br />
          {
            pedido?.schedules?.schedules && (
              <>
                AGENDADO: <span style={{ fontWeight: 'bold' }}>{fDateTimeS(pedido?.schedules?.schedules, 'Etc/Universal')}</span><br />
              </>
            )
          }
          <FormControl fullWidth style={{ marginTop: '10px' }}>
            <InputLabel id="status">Status</InputLabel>
            <Select
              labelId="Status"
              id="status"
              value={status}
              label="Status"
              onChange={handleStatus}
              disabled={['CANCELED', 'CONCLUDED'].includes(status)}
            >
              {
                Object.entries(DELIVERY_STATUS).map(([key, value], i) => (
                  <MenuItem value={key} key={key} disabled={isOptionDisabled(i)}>{value.name}</MenuItem>
                ))
              }
            </Select>
          </FormControl>
          <Button onClick={handleOpen} sx={{ marginTop: '10px', color: '#000' }} >Ver mais</Button>
        </CardContent>
      </Card>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h4" component="h4">
            Detalhes do pedido: <Button onClick={handleCopy}>Copiar</Button>
          </Typography>

          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            - Cliente -
          </Typography>

          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <span style={{ fontWeight: 'bold' }}>Nome:</span> {clientData?.name}
          </Typography>

          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <span style={{ fontWeight: 'bold' }}>Email: </span>{clientData?.email}
          </Typography>

          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <span style={{ fontWeight: 'bold' }}>Telefone: </span>{clientData?.phone}
          </Typography>

          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            - Pedido -
          </Typography>

          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <span style={{ fontWeight: 'bold' }}>Code: </span>{pedido.code}
          </Typography>

          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <span style={{ fontWeight: 'bold' }}>Status: </span>{DELIVERY_STATUS[pedido.status].name}
          </Typography>

          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <span style={{ fontWeight: 'bold' }}>Valor da entrega: </span>{`R$ ${pedido.deliveryFee}`}
          </Typography>

          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <span style={{ fontWeight: 'bold' }}>Valor total: </span>{`R$ ${pedido.subTotal}`}
          </Typography>

          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <span style={{ fontWeight: 'bold' }}>Tipo de pagamento: </span>{pedido.typePayment.name}
          </Typography>

          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <span style={{ fontWeight: 'bold' }}>Troco para: </span> R$ {Number(pedido.change ?? 0).toFixed(2)}
          </Typography>

          {
            pedido?.change && (
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <span style={{ fontWeight: 'bold' }}>Troco: </span> R$ {(Number(pedido.change ?? 0) - Number(pedido.subTotal)).toFixed(2)}
              </Typography>
            )
          }

          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <span style={{ fontWeight: 'bold' }}>Observação: </span>{pedido.observation}
          </Typography>

          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            - Endereço -
          </Typography>

          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <span style={{ fontWeight: 'bold' }}>Cidade: </span>{pedido?.orderAddress?.city}
          </Typography>

          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <span style={{ fontWeight: 'bold' }}>Estado: </span>{pedido?.orderAddress?.state}
          </Typography>

          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <span style={{ fontWeight: 'bold' }}>Rua: </span>{pedido?.orderAddress?.street}
          </Typography>

          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <span style={{ fontWeight: 'bold' }}>Bairro: </span>{pedido?.orderAddress?.neighborhood}
          </Typography>

          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <span style={{ fontWeight: 'bold' }}>Número: </span>{pedido?.orderAddress?.number}
          </Typography>

          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <span style={{ fontWeight: 'bold' }}>Complemento: </span>{pedido?.orderAddress?.complement}
          </Typography>

          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            - Produtos -
          </Typography>
          {
            pedido.orderProducts.map((item, index) => (
              <div key={index}>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  <span style={{ fontWeight: 'bold' }}>Nome: </span>{item.product.name}
                </Typography>

                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  <span style={{ fontWeight: 'bold' }}>Quantidade: </span>{item.quantity}
                </Typography>

                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  <span style={{ fontWeight: 'bold' }}>Preço: </span>{`R$ ${item.product.price}`}
                </Typography>

                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  <span style={{ fontWeight: 'bold' }}>Sub Total: </span>{`R$ ${item.subTotal}`}
                </Typography>

                <Divider sx={{ mt: 2, mb: 2 }} />
              </div>
            ))
          }
        </Box>
      </Modal>
    </Grid>
  );
}

OrderCard.propTypes = {
  pedido: PropTypes.object,
}