import { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText, Divider } from '@mui/material';

import Iconify from 'components/Iconify';

import { isFunction } from 'lodash';

MoreMenu.propTypes = {
  editable: PropTypes.func,
  deletable: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.exact({
      label: PropTypes.string,
      icon: PropTypes.string,
      action: PropTypes.func
    })
  )
};

function MoreMenu({ editable = null, deletable = null, options }) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Iconify icon="eva:more-vertical-fill" width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' },
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {
          options?.map(({ label, icon, action }, i) => (
            <MenuItem key={String(i)} sx={{ color: 'text.secondary' }} onClick={action}>
              <ListItemIcon>
                <Iconify icon={icon} width={24} height={24} />
              </ListItemIcon>
              <ListItemText primary={label} primaryTypographyProps={{ variant: 'body2' }} />
            </MenuItem>
          ))
        }
        {
          ((isFunction(editable) || isFunction(deletable)) && (options?.length > 0)) && (
            <Divider />
          )
        }
        {
          isFunction(editable) && (
            <MenuItem sx={{ color: 'text.secondary' }} onClick={editable}>
              <ListItemIcon>
                <Iconify icon="eva:edit-fill" width={24} height={24} />
              </ListItemIcon>
              <ListItemText primary="Editar" primaryTypographyProps={{ variant: 'body2' }} />
            </MenuItem>
          )
        }
        {
          isFunction(deletable) && (
            <MenuItem sx={{ color: 'text.secondary' }} onClick={deletable} dense>
              <ListItemIcon>
                <Iconify icon="eva:trash-2-fill" width={24} height={24} />
              </ListItemIcon>
              <ListItemText primary="Deletar" primaryTypographyProps={{ variant: 'body2' }} />
            </MenuItem>
          )
        }
      </Menu>
    </>
  );
}

export default MoreMenu;