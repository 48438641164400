import React, { createContext } from 'react';
import PropTypes from 'prop-types';

import useAuth from 'hooks/useAuth';

const propTypes = {
  children: PropTypes.PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

const AuthContext = createContext({});

function AuthProvider({ children }) {
  const { authenticated, loading, signin, signout } = useAuth();

  return (
    <AuthContext.Provider
      value={{
        authenticated,
        loading,
        signin,
        signout
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

AuthProvider.propTypes = propTypes;

export { AuthContext, AuthProvider };
