import { useState } from "react";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types"
import { isEmpty } from "lodash";
import { useForm } from "react-hook-form";
import { Grid } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { yupResolver } from "@hookform/resolvers/yup";
import { useActionSnackbar } from "hooks/useActionSnackbar";
import { Checkbox, FormProvider, TextField } from "components/hook-form";
import { format } from "date-fns";

import OperatingDaysService from "services/OperatingDaysService";
import OperationDaysCreateSchema from "../utils/validations/OperationDaysCreateValidation";

function setFormInitialValues(data) {
  return {
    timeStart: data?.timeStart ?? '08:00',
    timeFinish: data?.timeFinish ?? '19:00',
    isHoliday: data?.isHoliday ?? false,
    isClosed: data?.isClosed ?? false
  };
}

const FormOperation = ({ date, isPast, data = {}, mutate }) => {
  const [loading, setLoading] = useState(false);

  const { distributionCenterId } = useParams();
  const { addActionSnackbar } = useActionSnackbar();

  const methods = useForm({
    resolver: yupResolver(OperationDaysCreateSchema),
    defaultValues: setFormInitialValues(data)
  });

  const {
    handleSubmit,
    formState: { isSubmitting, isDirty, isValid }
  } = methods;
  
  const onSubmit = async d => {
    setLoading(true);

    try {
      d.data = format(date, 'yyyy-MM-dd');
      d.timeStartAndFinish = [d.timeStart, d.timeFinish];
      d.distributionCenterId = distributionCenterId;

      if (isEmpty(data)) {
        await OperatingDaysService.create(d);
      } else {
        await OperatingDaysService.update(data.id, d);
      }

      addActionSnackbar({
        severity: 'success',
        message: `Dia de funcionamento ${format(date, 'dd/MM/yyyy')} atualizado com sucesso!`
      });

      mutate();
    } catch (error) {
      addActionSnackbar({
        severity: 'error',
        message: error.message,
        statusList: error.badFields
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container gap={1}>
        <Grid item xs={3} align="center">
          <TextField
            type="time"
            name="timeStart"
            label="Horário de Início"
            autoComplete="off"
            InputLabelProps={{
              shrink: true
            }}
            disabled={isPast}
            required
          />
        </Grid>
        <Grid item xs={3} align="center">
          <TextField
            type="time"
            name="timeFinish"
            label="Horário de Término"
            autoComplete="off"
            InputLabelProps={{
              shrink: true
            }}
            disabled={isPast}
            required
          />
        </Grid>
        <Grid item xs={2} align="center">
          <Checkbox
            value="true"
            label="Feriado?"
            labelPlacement="top"
            name="isHoliday"
            disabled={isPast}
          />
        </Grid>
        <Grid item xs={2} align="center">
          <Checkbox
            value="true"
            label="Fechado?"
            labelPlacement="top"
            name="isClosed"
            disabled={isPast}
          />
        </Grid>
        <Grid
          item
          xs={1}
          align="center"
          sx={{ display: 'flex', alignItems: 'center' }}
        >
          <LoadingButton
            fullWidth
            size="medium"
            type="submit"
            variant="contained"
            disabled={isPast || ((!isDirty || !isValid) && !isEmpty(data))}
            loading={isSubmitting || loading}
          >
            Salvar
          </LoadingButton>
        </Grid>
      </Grid>
    </FormProvider>
  );
};

FormOperation.propTypes = {
  data: PropTypes.object,
  date: PropTypes.any,
  isPast: PropTypes.bool,
  mutate: PropTypes.func.isRequired
}

export default FormOperation;
