import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import {
  Toolbar,
  Tooltip,
  IconButton,
  Typography,
  OutlinedInput,
  InputAdornment
} from '@mui/material';
import Iconify from 'components/Iconify';

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3)
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`
  }
}));

TableToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
  customSearchPlaceholder: PropTypes.string,
  isSearchable: PropTypes.bool,
  isFilterable: PropTypes.bool
};

export default function TableToolbar({
  numSelected,
  filterName,
  onFilterName,
  customSearchPlaceholder = 'Pesquisar...',
  isSearchable = true,
  isFilterable = true
}) {
  return (
    <>
      {(isSearchable || isFilterable) && (
        <RootStyle
          sx={{
            ...(numSelected > 0 && {
              color: 'primary.main',
              bgcolor: 'primary.lighter'
            })
          }}
        >
          {isSearchable && (
            <>
              {numSelected > 0 ? (
                <Typography component="div" variant="subtitle1">
                  {numSelected} selecionado
                </Typography>
              ) : (
                <SearchStyle
                  value={filterName}
                  onChange={onFilterName}
                  placeholder={customSearchPlaceholder}
                  startAdornment={
                    <InputAdornment position="start">
                      <Iconify
                        icon="eva:search-fill"
                        sx={{ color: 'text.disabled', width: 20, height: 20 }}
                      />
                    </InputAdornment>
                  }
                />
              )}
            </>
          )}

          {isFilterable && (
            <>
              {numSelected > 0 ? (
                <Tooltip title="Deletar">
                  <IconButton>
                    <Iconify icon="eva:trash-2-fill" />
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip title="Filtrar">
                  <IconButton>
                    <Iconify icon="ic:round-filter-list" />
                  </IconButton>
                </Tooltip>
              )}
            </>
          )}
        </RootStyle>
      )}
    </>
  );
}
