import HttpClient from 'utils/HttpClient';
import mountQuery from 'utils/mountQuery';

class DistributionCentersService {
  findAll(page = 1, limit = 10, sortBy = {}, filterBy = {}) {
    return mountQuery('/distribution-centers', { page, limit, sortBy, filterBy });
  }

  findById(id) {
    return `/distribution-centers/${id}`;
  }

  async create(data) {
    return HttpClient.post('/distribution-centers', data);
  }

  async update(id, data) {
    return HttpClient.put(`/distribution-centers`, id, { 
      name: data.name,
      document: data.document,
      code: data.code
    });
  }

  async delete(id) {
    return HttpClient.delete(`/distribution-centers`, id);
  }
}

export default new DistributionCentersService();
