import PropTypes from "prop-types"
import React, { createContext, useCallback, useContext, useState } from 'react';

import ActionDialog from 'components/ActionDialog';

const ActionDialogContext = createContext({});

export const ActionDialogProvider = ({ children }) => {
  const [actionDialog, setActionDialog] = useState();

  const addActionDialog = useCallback(data => {
    setActionDialog(data);
  }, []);

  const removeActionDialog = useCallback(() => {
    setActionDialog(null);
  }, []);

  return (
    <ActionDialogContext.Provider
      value={{ addActionDialog, removeActionDialog }}
    >
      {children}
      {actionDialog && (
        <ActionDialog
          {...actionDialog}
          removeActionDialog={removeActionDialog}
        />
      )}
    </ActionDialogContext.Provider>
  );
};

ActionDialogProvider.propTypes = {
  children: PropTypes.PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
}

export function useActionDialog() {
  const context = useContext(ActionDialogContext);

  if (!context) {
    throw new Error(
      'useActionDialog must be used within a ActionDialogProvider'
    );
  }

  return context;
}
