import React from 'react';
import { isEmpty } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { Card, CardContent, Container, IconButton, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';

import Page from './Page';
import MoreMenu from './MoreMenu';
import Iconify from './Iconify';

function PageContainer({ children, headerComponent, headerPageComponent, title, menuOptions }) {
  const navigate = useNavigate();

  return (
    <Page {...{ title }}>
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          {title && (
            <Stack direction="row" spacing={2}>
              <IconButton
                onClick={() => navigate(-1)}
              >
                <Iconify icon="eva:arrow-back-outline" />
              </IconButton>
              <Typography variant="h4" gutterBottom>
                {title}
              </Typography>
            </Stack>
          )}
          <Stack direction="row" spacing={2}>
            {headerComponent}
            {!isEmpty(menuOptions) && (
              <MoreMenu
                editable={menuOptions?.editable}
                deletable={menuOptions?.deletable}
                options={menuOptions?.options}
              />
            )}
          </Stack>
        </Stack>
        <Stack
          direction="row" 
          spacing={1} 
          justifyContent="flex-end"
          alignItems="center"
        >
          {headerPageComponent}
        </Stack>
        <Card>
          <CardContent>{children}</CardContent>
        </Card>
      </Container>
    </Page>
  );
}

PageContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  title: PropTypes.string,
  headerComponent: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  headerPageComponent: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  menuOptions: PropTypes.object
};

export default React.memo(PageContainer);
