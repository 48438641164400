import Iconify from '../../components/Iconify';

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: getIcon('eva:pie-chart-2-fill'),
  },
  {
    title: 'funcionários',
    path: '/dashboard/employee',
    icon: getIcon('eva:people-fill'),
  },
  {
    title: 'centros de distribuição',
    path: '/dashboard/distribution-centers',
    icon: getIcon('map:moving-company'),
  },
  {
    title: 'clientes',
    path: '/dashboard/clients',
    icon: getIcon('ic:twotone-people-alt'),
  }
];

export default navConfig;
