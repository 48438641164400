import { Navigate, useRoutes } from 'react-router-dom';
import PrivateRoute from 'components/PrivateRoute';

import DashboardLayout from 'layouts/dashboard';
import LogoOnlyLayout from 'layouts/LogoOnlyLayout';

import Login from 'pages/Login/screens/Login';
import NotFound from 'pages/404';
import DashboardApp from 'pages/DashboardApp';

import Employee from 'pages/Employee/screens/EmployeeList';
import EmployeePage from 'pages/Employee/screens/EmployeePage';
import EmployeeCreate from 'pages/Employee/screens/EmployeeCreate';
import EmployeeUpdate from 'pages/Employee/screens/EmployeeUpdate';

import DistributionCenters from 'pages/DistributionCenters/screens/DistributionCenterList';
import DistributionCenterPage from 'pages/DistributionCenters/screens/DistributionCenterPage';
import DistributionCenterUpdate from 'pages/DistributionCenters/screens/DistributionCenterUpdate';
import DistributionCenterCreate from 'pages/DistributionCenters/screens/DistributionCenterCreate';

import DistributionCenterSettings from 'pages/DistributionCenterSettings/screens/DistributionCenterSettingsList';
import DistributionCenterSettingsPage from 'pages/DistributionCenterSettings/screens/DistributionCenterSettingsPage';
import DistributionCenterSettingsUpdate from 'pages/DistributionCenterSettings/screens/DistributionCenterSettingsUpdate';

import Categories from 'pages/Categories/screens/CategoriesList';
import CategoriesPage from 'pages/Categories/screens/CategoriesPage';
import CategoriesCreate from 'pages/Categories/screens/CategoriesCreate';
import CategoriesUpdate from 'pages/Categories/screens/CategoriesUpdate';

import CategoriesImagesCreate from 'pages/CategoriesImages/screens/CategoriesImagesCreate';

import Brands from 'pages/Brands/screens/BrandsList';
import BrandsCreate from 'pages/Brands/screens/BrandsCreate';
import BrandsPage from 'pages/Brands/screens/BrandsPage';
import BrandsUpdate from 'pages/Brands/screens/BrandsUpdate';

import BrandsImagesCreate from 'pages/BrandsImages/screens/BrandsImagesCreate';

import Products from 'pages/Products/screens/ProductsList';
import ProductsCreate from 'pages/Products/screens/ProductsCreate';
import ProductsPage from 'pages/Products/screens/ProductsPage';
import ProductsUpdate from 'pages/Products/screens/ProductsUpdate';

import ProductsImagesCreate from 'pages/ProductsImages/screens/ProductsImagesCreate';

import DiscountsCreate from 'pages/ProductsDiscounts/screens/DiscountsCreate';
import Discounts from 'pages/ProductsDiscounts/screens/DiscountsList';
import DiscountsPage from 'pages/ProductsDiscounts/screens/DiscountsPage';

import TypesPayments from 'pages/TypesPayments/screens/TypesPaymentsList';
import TypesPaymentsCreate from 'pages/TypesPayments/screens/TypesPaymentsCreate';
import TypesPaymentsPage from 'pages/TypesPayments/screens/TypesPaymentsPage';
import TypesPaymentsUpdate from 'pages/TypesPayments/screens/TypesPaymentsUpdate';

import Orders from 'pages/Order/screens/OrderList';
import OperatingDaysList from 'pages/OperatingDays/screens/OperatingDaysList';

import Clients from 'pages/Clients/screens/ClientsList';
import ClientsPage from 'pages/Clients/screens/ClientsPage';
import ClientsOrders from 'pages/Clients/screens/ClientsOrder';

const requireAuth = (element, isRequired) =>
  isRequired ? <PrivateRoute element={element} /> : element;

export default function Router() {
  return useRoutes([
    {
      path: '/dashboard',
      element: requireAuth(<DashboardLayout />, true),
      children: [
        { path: 'app', element: <DashboardApp /> },
        {
          path: 'employee',
          element: <Employee />
        },
        {
          path: 'employee/create',
          element: <EmployeeCreate />
        },
        {
          path: 'employee/:employeeId',
          element: <EmployeePage />
        },
        {
          path: 'employee/:employeeId/update',
          element: <EmployeeUpdate />
        },
        {
          path: 'distribution-centers',
          element: <DistributionCenters />
        },
        {
          path: 'distribution-centers/create',
          element: <DistributionCenterCreate />
        },
        {
          path: 'distribution-centers/:distributionCenterId',
          element: <DistributionCenterPage />
        },
        {
          path: 'distribution-centers/:distributionCenterId/update',
          element: <DistributionCenterUpdate />
        },
        {
          path: 'distribution-centers/:distributionCenterId/settings',
          element: <DistributionCenterSettings />
        },
        {
          path: 'distribution-centers/:distributionCenterId/settings/:settingId',
          element: <DistributionCenterSettingsPage />
        },
        {
          path: 'distribution-centers/:distributionCenterId/settings/:settingId/update',
          element: <DistributionCenterSettingsUpdate />
        },
        {
          path: 'distribution-centers/:distributionCenterId/categories',
          element: <Categories />
        },
        {
          path: 'distribution-centers/:distributionCenterId/categories/create',
          element: <CategoriesCreate />
        },
        {
          path: 'distribution-centers/:distributionCenterId/categories/:categoryId',
          element: <CategoriesPage />
        },
        {
          path: 'distribution-centers/:distributionCenterId/categories/:categoryId/update',
          element: <CategoriesUpdate />
        },
        {
          path: 'distribution-centers/:distributionCenterId/categories/:categoryId/images/create',
          element: <CategoriesImagesCreate />
        },
        {
          path: 'distribution-centers/:distributionCenterId/brands',
          element: <Brands />
        },
        {
          path: 'distribution-centers/:distributionCenterId/brands/create',
          element: <BrandsCreate />
        },
        {
          path: 'distribution-centers/:distributionCenterId/brands/:brandId',
          element: <BrandsPage />
        },
        {
          path: 'distribution-centers/:distributionCenterId/brands/:brandId/update',
          element: <BrandsUpdate />
        },
        {
          path: 'distribution-centers/:distributionCenterId/brands/:brandId/images/create',
          element: <BrandsImagesCreate />
        },
        {
          path: 'distribution-centers/:distributionCenterId/products',
          element: <Products />
        },
        {
          path: 'distribution-centers/:distributionCenterId/products/create',
          element: <ProductsCreate />
        },
        {
          path: 'distribution-centers/:distributionCenterId/products/:productId',
          element: <ProductsPage />
        },
        {
          path: 'distribution-centers/:distributionCenterId/products/:productId/update',
          element: <ProductsUpdate />
        },
        {
          path: 'distribution-centers/:distributionCenterId/products/:productId/images/create',
          element: <ProductsImagesCreate />
        },
        {
          path: 'distribution-centers/:distributionCenterId/products/:productId/discounts',
          element: <Discounts/>
        },
        {
          path: 'distribution-centers/:distributionCenterId/products/:productId/discounts/:discountId',
          element: <DiscountsPage/>
        },
        {
          path: 'distribution-centers/:distributionCenterId/products/:productId/discounts/create',
          element: <DiscountsCreate />
        },
        {
          path: 'distribution-centers/:distributionCenterId/types-payments',
          element: <TypesPayments />
        },
        {
          path: 'distribution-centers/:distributionCenterId/types-payments/create',
          element: <TypesPaymentsCreate />
        },
        {
          path: 'distribution-centers/:distributionCenterId/types-payments/:typePaymentId',
          element: <TypesPaymentsPage />
        },
        {
          path: 'distribution-centers/:distributionCenterId/types-payments/:typePaymentId/update',
          element: <TypesPaymentsUpdate />
        },
        {
          path: 'distribution-centers/:distributionCenterId/orders',
          element: <Orders />
        },
        {
          path: 'distribution-centers/:distributionCenterId/operating-days',
          element: <OperatingDaysList />
        },
        {
          path: 'clients',
          element: <Clients />
        },
        {
          path: 'clients/:clientId',
          element: <ClientsPage />
        },
        {
          path: 'clients/:clientId/orders',
          element: <ClientsOrders />
        },
      ]
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '/', element: <Navigate to="/dashboard/app" /> },
        { path: 'login', element: <Login /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <NotFound /> }
      ]
    },
    { path: '*', element: <NotFound /> }
  ]);
}
