import { fDateTimeS } from "utils/formatTime";

export const TABLE_HEAD = [
  { id: 'value', label: 'Desconto', alignRight: false, orderable: true },
  { id: 'minimumQuantity', label: 'Quantidade mínima', alignRight: false, orderable: true },
  { id: 'expiresAt', label: 'Expira em', alignRight: false, orderable: true }
];

export const formatTableData = (data) => ({
  key: data?.id,
  columns: [
    { render: `${data?.value}%` },
    { render: data?.minimumQuantity },
    { render: data?.expiresAt ? fDateTimeS(data?.expiresAt) : '' } 
  ]
});
