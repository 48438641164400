import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Button, Typography, Container } from '@mui/material';
import Page from '../components/Page';

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0)
}));

export default function Page404() {
  const navigate = useNavigate();

  return (
    <Page title="404 - Página não encontrada :(">
      <Container>
        <ContentStyle sx={{ textAlign: 'center', alignItems: 'center' }}>
          <Typography variant="h3" paragraph>
            Página não encontrada {":("}
          </Typography>

          <Typography sx={{ color: 'text.secondary', mb: 6 }}>
            Desculpe, essa página não foi encontrada.
          </Typography>

          <Button onClick={() => navigate(-1)} size="large" variant="contained">
            Voltar
          </Button>
        </ContentStyle>
      </Container>
    </Page>
  );
}
