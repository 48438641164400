import errorMessage from './errorMessage';

export class APIError extends Error {
  constructor(response) {
    super();

    this.name = 'APIError';
    this.response = response;
    this.body = response.data;
    this.badFields = response?.data?.badFields || [];
    this.message = errorMessage(response.data);
  }
}
