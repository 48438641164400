import HttpClient from 'utils/HttpClient';
import mountQuery from 'utils/mountQuery';

class DistributionCentersSettingsService {
  findAll(page = 1, limit = 10, sortBy = {}, filterBy = {}) {
    return mountQuery('/distribution-centers-settings', { page, limit, sortBy, filterBy });
  }

  findById(id) {
    return `/distribution-centers-settings/${id}`;
  }

  findByDistributionCenter(id, page = 1, limit = 10, sortBy = {}, filterBy = {} ) {
    return mountQuery(`/distribution-centers-settings/by/distribution-center/${id}`,{ 
      page,
      limit,
      sortBy,
      filterBy 
    });
  }

  async create(data) {
    return HttpClient.post('/distribution-centers-settings', data);
  }

  async update(id, data) {
    return HttpClient.put(`/distribution-centers-settings`, id, { 
      value: data.value,
      type: data.type
    });
  }

  async delete(id) {
    return HttpClient.delete(`/distribution-centers-settings`, id);
  }
}

export default new DistributionCentersSettingsService();
