import { fDateTimeS } from "utils/formatTime";

export const TABLE_HEAD = [
  { id: 'name', label: 'Nome', alignRight: false, orderable: true },
  { id: 'createdAt', label: 'Criado em', alignRight: false, orderable: true }
];

export const formatTableData = (data) => ({
  key: data?.id,
  columns: [
    { render: data?.name },
    { render: fDateTimeS(data?.createdAt) }
  ]
});
