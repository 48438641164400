/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from "prop-types"
import React, { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import ConfirmAccess from 'components/ConfirmAccess';

const ConfirmAccessContext = createContext({});

export const ConfirmAccessProvider = ({ children }) => {
  const location = useLocation();
  const pageAccess = useRef(null);

  const [confirmAccess, setConfirmAccess] = useState();

  useEffect(() => {
    const page = pageAccess.current;
    
    if (confirmAccess && location.pathname !== page) {
      removeConfirmAccess();
    }
  }, [location]); 

  const addConfirmAccess = useCallback((data) => {
    pageAccess.current = location.pathname;

    return new Promise((resolve, reject) => {
      setConfirmAccess({ ...data, resolve, reject, removeConfirmAccess });
    });
  }, []);

  const removeConfirmAccess = useCallback(() => {
    setConfirmAccess(null);
  }, []);

  return (
    <ConfirmAccessContext.Provider
      value={{ addConfirmAccess, removeConfirmAccess }}
    >
      {children}
      {confirmAccess && <ConfirmAccess {...confirmAccess} />}
    </ConfirmAccessContext.Provider>
  );
};

ConfirmAccessProvider.propTypes = {
  children: PropTypes.PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
}

export function useConfirmAccess() {
  const context = useContext(ConfirmAccessContext);

  if (!context) {
    throw new Error(
      'useConfirmAccess must be used within a ConfirmAccessProvider'
    );
  }

  return context;
}
