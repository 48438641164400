/* eslint-disable no-restricted-syntax */
const dataToFormData = (inJSON, inTestJSON, inFormData, parentKey) => {
  // http://stackoverflow.com/a/22783314/260665
  // Raj: Converts any nested JSON to formData.
  const formData = inFormData || new FormData();
  const testJSON = inTestJSON || {};
  // eslint-disable-next-line guard-for-in
  for (const key in inJSON) {
    // 1. If it is a recursion, then key has to be constructed like "parent.child" where parent JSON contains a child JSON
    // 2. Perform append data only if the value for key is not a JSON, recurse otherwise!
    let constructedKey = key;
    if (parentKey) {
      constructedKey = `${parentKey}.${key}`;
    }

    const value = inJSON[key];
    if (value && value.constructor === {}.constructor) {
      // This is a JSON, we now need to recurse!
      dataToFormData(value, testJSON, formData, constructedKey);
    } else {
      formData.append(constructedKey, inJSON[key]);
      testJSON[constructedKey] = inJSON[key];
    }
  }
  return formData;
};

export default dataToFormData;
