import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Grid } from '@mui/material';
import { TextField, FormProvider } from 'components/hook-form';
import { isEmpty } from 'lodash';
import { useActionSnackbar } from 'hooks/useActionSnackbar';
import { LoadingButton } from '@mui/lab';
import { yupResolver } from '@hookform/resolvers/yup';
import { useConfirmAccess } from 'hooks/useConfirmAccess';
import GridContainer from 'components/GridContainer';
import Error from 'components/Error';
import Loader from 'components/Loader';
import PageContainer from 'components/PageContainer';
import useFetcher from 'hooks/useFetcher';
import EmployeeService from 'services/EmployeeService';
import Iconify from 'components/Iconify';

import UpdateEmployeeSchema from '../utils/validations/EmployeeUpdateValidation';

function EmployeeUpdate() {

  const navigate = useNavigate();
  const { addActionSnackbar } = useActionSnackbar();
  const [load, setLoading] = useState(false);
  const { employeeId } = useParams();
  const { addConfirmAccess } = useConfirmAccess();

  const { data, loading, error } = useFetcher(
    EmployeeService.findById(employeeId)
  );

  const methods = useForm({
    resolver: yupResolver(UpdateEmployeeSchema)
  });
  
  const {
    handleSubmit,
    formState: { isSubmitting }
  } = methods;

  const onSubmit = async data => {

    const password = await addConfirmAccess();

    setLoading(true);

    try {

      const res = await EmployeeService.update(employeeId, data, password);

      if (!isEmpty(res)) {
        addActionSnackbar({
          severity: 'success',
          message: 'Funcionário atualizado com sucesso!'
        });
        navigate(-1);
      }
    } catch (error) {
      addActionSnackbar({
        severity: 'error',
        message: error.message,
        statusList: error.badFields
      });
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    methods.reset({
      name: data?.name || '',
      document: data?.document || '',
      phone: data?.phone || '',
      email: data?.email || '',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (error) return <Error {...{ error }} />;

  return (
    <PageContainer
      title="Atualização de Funcionário"
      headerComponent={
          <Button
            onClick={()=> navigate(-1)}
            variant="contained"
            to="#"
            startIcon={<Iconify icon="akar-icons:circle-x-fill" />}
          >
            Cancelar
          </Button>
      }
    >
      {loading ? (
        <Loader  />
      ) : (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <GridContainer>

            <Grid item xs={12}>
              <TextField type="text" name="name" label="Nome" required />
            </Grid>

            <Grid item xs={12}>
              <TextField
                type="text"
                name="document"
                label="Documento"
                mask="cpf"
                required
              />
            </Grid>

            <Grid item xs={12}>
              <TextField type="tel" name="phone" label="Telefone" required />
            </Grid>

            <Grid item xs={12}>
              <TextField type="email" name="email" label="E-mail" required />
            </Grid>

            <Grid item xs={12}>
              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting || load}
              >
                Atualizar
              </LoadingButton>
            </Grid>

          </GridContainer>
        </FormProvider>
      )}
    </PageContainer>
  );
}

export default EmployeeUpdate;
