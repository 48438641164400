import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
import {
  Checkbox as MaterialCheckbox,
  FormControlLabel,
  FormGroup
} from '@mui/material';

Checkbox.propTypes = {
  name: PropTypes.string.isRequired
};

export function Checkbox({ name, ...other }) {
  const { control } = useFormContext();

  return (
    <FormControlLabel
      control={
        <Controller
          name={name}
          control={control}
          render={({ field: props }) => (
            <MaterialCheckbox
              {...props}
              checked={!!props.value}
              onChange={e => props.onChange(e.target.checked)}
            />
          )}
        />
      }
      {...other}
    />
  );
}

MultiCheckbox.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired
};

export function MultiCheckbox({ name, options, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        const onSelected = option =>
          field.value.includes(option)
            ? field.value.filter(value => value !== option)
            : [...field.value, option];

        return (
          <FormGroup>
            {options.map(option => (
              <FormControlLabel
                key={option.value}
                control={
                  <MaterialCheckbox
                    checked={field.value.includes(option.value)}
                    onChange={() => field.onChange(onSelected(option.value))}
                  />
                }
                label={option.label}
                {...other}
              />
            ))}
          </FormGroup>
        );
      }}
    />
  );
}
