import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { isEmpty } from 'lodash';
import { Grid } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { yupResolver } from '@hookform/resolvers/yup';

import PageContainer from 'components/PageContainer';
import GridContainer from 'components/GridContainer';
import { FormProvider, TextField, Checkbox } from 'components/hook-form';

import { useActionSnackbar } from 'hooks/useActionSnackbar';
import CategoriesService from 'services/CategoriesService';

import CategoriesCreateSchema from '../utils/validations/CategoriesCreateValidation';

function setFormInitialValues() {
  return {
    name: '',
    enabled: false
  };
}

function CategoriesCreate() {
  const [loading, setLoading] = useState(false);
  const { distributionCenterId } = useParams();
  const { addActionSnackbar } = useActionSnackbar();

  const navigate = useNavigate();

  const methods = useForm({
    resolver: yupResolver(CategoriesCreateSchema),
    defaultValues: setFormInitialValues()
  });

  const {
    handleSubmit,
    formState: { isSubmitting }
  } = methods;

  const onSubmit = async data => {
    setLoading(true);

    try {
      data.distributionCenterId = distributionCenterId;
      const res = await CategoriesService.create(data);

      if (!isEmpty(res)) {
        addActionSnackbar({
          severity: 'success',
          message: 'Categoria cadastrada com sucesso!'
        });
        navigate(`/dashboard/distribution-centers/${distributionCenterId}/categories/${res.id}`);
      }
    } catch (error) {
      addActionSnackbar({
        severity: 'error',
        message: error.message,
        statusList: error.badFields
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <PageContainer title="Cadastrar Categoria">
       <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <GridContainer>

          <Grid item xs={12}>
            <TextField 
              type="text" 
              name="name" 
              label="Nome" 
              autoComplete="off" 
              required
            />
          </Grid>

          <Grid item xs={12}>
            <Checkbox name="enabled" label="Habilitado?"/>
          </Grid>

          <Grid item xs={12}>
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting || loading}
            >
              Cadastrar
            </LoadingButton>
          </Grid>

        </GridContainer>
      </FormProvider>
    </PageContainer>
  );
}

export default CategoriesCreate;
