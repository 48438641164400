export class SystemError extends Error {
  constructor(response) {
    super();

    this.name = 'SystemError';
    this.response = response;
    this.body = response.data;
    this.badFields = response?.data?.badFields || [];
    this.message = response?.data?.message;
  }
}
