import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { Grid } from '@mui/material';
import { useActionDialog } from 'hooks/useActionDialog';
import { useActionSnackbar } from 'hooks/useActionSnackbar';
import { isEmpty } from 'lodash';

import { TextField, FormProvider } from 'components/hook-form';
import GridContainer from 'components/GridContainer';
import Error from 'components/Error';
import Loader from 'components/Loader';
import PageContainer from 'components/PageContainer';
import ImageGallery from 'components/ImageGallery';

import useFetcher from 'hooks/useFetcher';
import CategoriesService from 'services/CategoriesService';
import CategoriesImagesService from 'services/CategoriesImagesService';
import { formatGalleryData } from '../utils/table';

function CategoryPage() {
  const methods = useForm({});
  const navigate = useNavigate();
  const { categoryId, distributionCenterId } = useParams();

  const { addActionSnackbar } = useActionSnackbar();
  const { addActionDialog } = useActionDialog();
  const { data, loading, error, mutate } = useFetcher(
    CategoriesService.findById(categoryId)
  );

  function onAccept(){
    handleDelete(categoryId);
  }

  function handleConfirmDelete(){
    addActionDialog({
      message: 'Deseja realmente deletar esta categoria?',
      title: 'Confirmação de exclusão',
      onAccept
    });
  }

  function handleUpdate(id){
    navigate(`/dashboard/distribution-centers/${distributionCenterId}/categories/${id}/update`);
  }

  const handleDelete = async id => {
    try {
      const res = await CategoriesService.delete(id);
      
      if (!isEmpty(res)) {
        addActionSnackbar({
          severity: 'success',
          message: 'Categoria deletada com sucesso!'
        });

        navigate(-1);
      }
    } catch (error) {
      addActionSnackbar({
        severity: 'error',
        message: error.message,
        statusList: error.badFields
      });
    }
  }

  const handleDeleteImage = async id => {
    try {
      const res = await CategoriesImagesService.delete(id);

      if (!isEmpty(res)) {
        addActionSnackbar({
          severity: 'success',
          message: 'Imagem deletada com sucesso!'
        });

        mutate();
      }
    } catch (error) {
      addActionSnackbar({
        severity: 'error',
        message: error.message,
        statusList: error.badFields
      });
    }
  }

  useEffect(() => {
    methods.reset({
      name: data?.name || '',
      enabled: data?.enabled ? 'Habilitado' : 'Não habilitado'
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (error) return <Error {...{ error }} />;

  return (
    <PageContainer
      title="Painel de Categoria"
      menuOptions={{
        editable: () => handleUpdate(data?.id),
        deletable: () => handleConfirmDelete(data?.id)
      }}
    >
      {loading ? (
        <Loader />
      ) : (
        <FormProvider methods={methods}>
          <GridContainer>
            <Grid item xs={12}>
              <TextField 
                type="text" 
                name="name" 
                label="Nome" 
                readOnly
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                type="text"
                name="enabled"
                label="Está habilitado?"
                autoComplete="off"
                required
                readOnly
              />
            </Grid>
          </GridContainer>

          <ImageGallery 
            to={`/dashboard/distribution-centers/${distributionCenterId}/categories/${categoryId}/images/`}
            data={data?.images}
            formatImagesData={formatGalleryData}
            cols={2}
            addMore={data?.images?.length < 2}
            onDelete={(imageId) => handleDeleteImage(imageId)}
            isClickable={false}
          />
        </FormProvider>
      )}
    </PageContainer>
  );
}

export default CategoryPage;