import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useActionSnackbar } from 'hooks/useActionSnackbar';
import useFetcher from 'hooks/useFetcher';
import { Button, Grid, Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import { LoadingButton } from '@mui/lab';
import { yupResolver } from '@hookform/resolvers/yup';

import { TextField, FormProvider, Checkbox, AsyncSelectField } from 'components/hook-form';
import GridContainer from 'components/GridContainer';
import Error from 'components/Error';
import Loader from 'components/Loader';
import PageContainer from 'components/PageContainer';
import Iconify from 'components/Iconify';

import ProductsService from 'services/ProductsService';
import ProductsUpdateSchema from '../utils/validations/ProductsUpdateValidation';

function ProductsUpdate() {
  const { addActionSnackbar } = useActionSnackbar();
  const navigate = useNavigate();
  const [load, setLoading] = useState(false);
  const [parentSearch, setParentSearch] = useState('');
  const { productId, distributionCenterId } = useParams();

  const { data, loading, error, mutate } = useFetcher(
    ProductsService.findById(productId)
  );

  const methods = useForm({
    resolver: yupResolver(ProductsUpdateSchema)
  });

  const {
    handleSubmit,
    formState: { isSubmitting }
  } = methods;

  const onSubmit = async productData => {
    setLoading(true);

    try {
      if (productData.variantOf === '') {
        productData.variantOf = null;
      }

      const res = await ProductsService.update(productId, productData);

      if (!isEmpty(res)) {
        addActionSnackbar({
          severity: 'success',
          message: 'Produto atualizado com sucesso!'
        });

        mutate();
        navigate(-1);
      }
    } catch (error) {
      addActionSnackbar({
        severity: 'error',
        message: error.message,
        statusList: error.badFields
      });
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    methods.reset({
      name: data?.name || '',
      description: data?.description || '',
      price: data?.price || 0,
      stock: data?.stock || 0,
      toHighlight: data?.toHighlight || false,
      variantOf: data?.variantOf?.id || ''
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (error) return <Error {...{ error }} />;

  return (
    <PageContainer
      title="Atualização de Produto"
      headerComponent={
          <Button
            onClick={()=> navigate(-1)}
            variant="contained"
            to="#"
            startIcon={<Iconify icon="akar-icons:circle-x-fill" />}
          >
            Cancelar
          </Button>
      }
    >
      {loading ? (
        <Loader />
      ) : (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)} >
          <GridContainer>
            <Grid item xs={12}>
              <Typography variant="h5" gutterBottom>
                {data.name}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <TextField 
                type="text" 
                name="name" 
                label="Nome"
                required
              />
            </Grid>

            <Grid item xs={12}>
              <TextField 
                type="number" 
                name="price" 
                label="Preço" 
                autoComplete="off" 
                required
              />
            </Grid>

            <Grid item xs={12}>
              <TextField 
                type="number" 
                name="stock" 
                label="Estoque" 
                autoComplete="off" 
                required
              />
            </Grid>

            <Grid item xs={12}>
              <Checkbox name="toHighlight" label="Destaque"/>
            </Grid>

            <Grid item xs={12}>
              <TextField
                type="text"
                name="description"
                label="Descrição"
                autoComplete="off"
                multiline
                rows={3}
                required
              />
            </Grid>

            {
              data.variantOf && (
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom>
                    Variante de: {data.variantOf.name}
                  </Typography>
                </Grid>
              )
            }

            <Grid item xs={12}>
              <AsyncSelectField
                name="variantOf"
                label="Atualize a variante"
                url={ProductsService.findByDistributionCenter(
                  distributionCenterId,
                  1,
                  20,
                  {},
                  { search: parentSearch }
                )}
                onInputChange={setParentSearch}
                defaultOptions={[
                  { id: '', name: 'Nenhum' }
                ]}
              />
            </Grid>

            <Grid item xs={12}>
              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting || load}
              >
                Atualizar
              </LoadingButton>
            </Grid>

          </GridContainer>
        </FormProvider>
      )}
    </PageContainer>
  );
}

export default ProductsUpdate;