import HttpClient from 'utils/HttpClient';
import mountQuery from 'utils/mountQuery';

class OperatingDaysService {
  constructor() {
    this.url = '/operating-days';
  }

  findById(id) {
    return `${this.url}/${id}`;
  }

  findByDistributionCenter(id, { page = 1, limit = 8, sortBy = {}, filterBy = {} }) {
    return mountQuery(`${this.url}/by/distribution-center/${id}`, { 
      page,
      limit,
      sortBy,
      filterBy 
    });
  }

  async create(data) {
    return HttpClient.post(this.url, data);
  }

  async update(id, data) {
    return HttpClient.put(this.url, id, data);
  }

  async delete(id) {
    return HttpClient.delete(this.url, id);
  }
}

export default new OperatingDaysService();
