import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { Grid } from '@mui/material';
import { useActionDialog } from 'hooks/useActionDialog';
import { useActionSnackbar } from 'hooks/useActionSnackbar';
import { isEmpty } from 'lodash';

import { TextField, FormProvider } from 'components/hook-form';
import GridContainer from 'components/GridContainer';
import Error from 'components/Error';
import Loader from 'components/Loader';
import PageContainer from 'components/PageContainer';

import useFetcher from 'hooks/useFetcher';
import DistributionCentersSettingsService from 'services/DistributionCentersSettingsService';
import { fDateTimeS } from 'utils/formatTime';

function DistributionCenterSettingsPage() {
  const methods = useForm({});
  const navigate = useNavigate();
  const { settingId, distributionCenterId } = useParams();

  const { addActionSnackbar } = useActionSnackbar();
  const { addActionDialog } = useActionDialog();
  const { data, loading, error, mutate } = useFetcher(
    DistributionCentersSettingsService.findById(settingId)
  );

  function onAccept(){
    handleDelete(settingId);
  }

  function handleConfirmDelete(){
    addActionDialog({
      message: 'Deseja realmente deletar esta configuração?',
      title: 'Confirmação de exclusão',
      onAccept
    });
  }

  function handleUpdate(id){
    navigate(`/dashboard/distribution-centers/${distributionCenterId}/settings/${id}/update`);
  }

  const handleDelete = async id => {
    try {
      const res = await DistributionCentersSettingsService.delete(id);
      
      if (!isEmpty(res)) {
        addActionSnackbar({
          severity: 'success',
          message: 'Configuração deletada com sucesso!'
        });

        navigate(-1);
      }
    } catch (error) {
      addActionSnackbar({
        severity: 'error',
        message: error.message,
        statusList: error.badFields
      });
    }
  }
 
  useEffect(() => {
    methods.reset({
      name: data?.setting.name || '',
      value: data?.value || '',
      type: data?.setting.type || '',
      created: data?.setting.createdAt ? fDateTimeS(data?.createdAt) : ''
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (error) return <Error {...{ error }} />;

  return (
    <PageContainer
      title="Painel de Configuração"
      menuOptions={{
        editable: () => handleUpdate(data?.id),
        deletable: () => handleConfirmDelete(data?.id)
      }}
    >
      {loading ? (
        <Loader />
      ) : (
        <FormProvider methods={methods}>
          <GridContainer>
            <Grid item xs={12}>
              <TextField 
                type="text" 
                name="name" 
                label="Nome" 
                readOnly
              />
            </Grid>
            <Grid item xs={12}>
              <TextField 
                type="text" 
                name="value" 
                label="Valor" 
                readOnly
              />
            </Grid>
            <Grid item xs={12}>
              <TextField 
                type="text" 
                name="type" 
                label="Tipo" 
                readOnly
              />
            </Grid>
            <Grid item xs={12}>
              <TextField 
                type="text" 
                name="created" 
                label="Criado em" 
                readOnly
              />
            </Grid>
          </GridContainer>
        </FormProvider>
      )}
    </PageContainer>
  );
}

export default DistributionCenterSettingsPage;