import HttpClient from 'utils/HttpClient';
import mountQuery from 'utils/mountQuery';

class CategoriesService {
  findAll(page = 1, limit = 10, sortBy = {}, filterBy = {}) {
    return mountQuery('/categories', { page, limit, sortBy, filterBy });
  }

  findById(id) {
    return `/categories/${id}`;
  }

  findByDistributionCenter(id, page = 1, limit = 10, sortBy = {}, filterBy = {}) {
    return mountQuery(`/categories/by/distribution-center/${id}`, { 
      page,
      limit,
      sortBy,
      filterBy 
    });
  }

  async create(data) {
    return HttpClient.post('/categories', data);
  }

  async update(id, data) {
    return HttpClient.put(`/categories`, id, { 
      name: data.name,
      enabled: data.enabled
    });
  }

  async delete(id) {
    return HttpClient.delete(`/categories`, id);
  }
}

export default new CategoriesService();
