import { memo, useContext } from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { CircularProgress } from '@mui/material';

import { AuthContext } from 'contexts/AuthContext';

const propTypes = {
  element: PropTypes.PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

function PrivateRoute({ element }) {
  const { loading, authenticated } = useContext(AuthContext);

  if (loading) return <CircularProgress />;

  return authenticated ? element : <Navigate to="/login" />;
}

PrivateRoute.propTypes = propTypes;

export default memo(PrivateRoute);
