import PropTypes from "prop-types"
import React, { createContext, useCallback, useContext } from 'react';
import { ToastContainer, toast } from 'react-toastify';

import ActionSnackbar from 'components/ActionSnackbar';

import 'react-toastify/dist/ReactToastify.min.css';
import './style.css';

const ActionSnackbarContext = createContext({});

export const ActionSnackbarProvider = ({ children }) => {
  const addActionSnackbar = useCallback(
    ({ severity, message, statusList, autoClose = 5000 }) => {
      toast(<ActionSnackbar {...{ severity, message, statusList }} />, {
        autoClose,
        className: 'action-snackbar-toast'
      });
    },
    []
  );

  return (
    <ActionSnackbarContext.Provider value={{ addActionSnackbar }}>
      <ToastContainer position={toast.POSITION.BOTTOM_LEFT} newestOnTop />
      {children}
    </ActionSnackbarContext.Provider>
  );
};

ActionSnackbarProvider.propTypes = {
  children: PropTypes.PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
}

export function useActionSnackbar() {
  const context = useContext(ActionSnackbarContext);

  if (!context) {
    throw new Error(
      'useActionSnackbar must be used within a ActionSnackbarProvider'
    );
  }

  return context;
}
