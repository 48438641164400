import PropTypes from "prop-types"
import { TableCell } from "@mui/material";

const Cell = ({ children, isPast, hasData }) => (
  <TableCell
    align="left"
    sx={{
      color: isPast ? '#AAA' : '#212B36',
      fontStyle: hasData ? 'normal' : 'italic',
      fontWeight: hasData ? 'bold' : 'inherit',
    }}
  >
    {children}
  </TableCell>
);

Cell.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  hasData: PropTypes.bool,
  isPast: PropTypes.bool
}

export default Cell;
