import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Typography,
  IconButton,
  Button
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/system';
import { isEmpty, isFunction } from 'lodash';
import { Controlled as ControlledZoom } from 'react-medium-image-zoom';

import useResponsive from 'hooks/useResponsive';

import { useActionDialog } from 'hooks/useActionDialog';
import Iconify from './Iconify';

import 'react-medium-image-zoom/dist/styles.css';

const propTypes = {
  title: PropTypes.string,
  data: PropTypes.array,
  variant: PropTypes.string,
  to: PropTypes.string,
  cols: PropTypes.number,
  gap: PropTypes.number,
  loading: PropTypes.bool,
  formatImagesData: PropTypes.func,
  addMore: PropTypes.bool,
  isClickable: PropTypes.bool,
  onDelete: PropTypes.func
};

function ImageGallery({
  title = 'Imagens: ',
  data = [],
  variant = 'quilted',
  to = '',
  cols = 3,
  gap = 8,
  loading = false,
  formatImagesData = null,
  addMore = false,
  isClickable = true,
  onDelete
}) {
  const navigate = useNavigate();

  const isMobile = useResponsive('down', 'md');
  const { addActionDialog } = useActionDialog();

  const c = useMemo(() => {
    if (isMobile) return 1;

    return cols;
  }, [cols, isMobile]);

  const formattedImagesData = useMemo(
    () =>
      !loading && !isEmpty(data) && isFunction(formatImagesData)
        ? data?.map(formatImagesData)
        : [],
    [data, loading, formatImagesData]
  );

  const handleClick = useCallback(id => {
    if (!isEmpty(to) && !isEmpty(id) && isClickable) {
      navigate(`${to}${id}`);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [to, isClickable]);
  
  const handleDelete = useCallback(id => {
    addActionDialog({
      title: 'Confirmação de exclusão',
      message: 'Deseja realmente deletar esta imagem?',
      onAccept: () => onDelete(id)
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onDelete]);

  return (
    <Box sx={{ marginTop: 2 }}>
      <Typography variant="h6" paragraph sx={{ marginBottom: 1 }}>
        {title}
      </Typography>
      <ImageList sx={{ width: '100%' }} variant={variant} cols={c} gap={gap}>
        {formattedImagesData.map((item, i) => (
          <ImageListItem
            key={item?.id || String(i)}
            sx={{
              borderRadius: 1.5,
              overflow: 'hidden',
              cursor: isClickable ? 'pointer' : 'default',
              maxHeight: '300px'
            }}
          >
            <ZoomableImage {...{ item, to, handleClick, handleDelete }} />
          </ImageListItem>
        ))}
        {addMore && (
          <ImageListItem
            sx={{
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              ...(isMobile && { justifyContent: 'center' })
            }}
          >
            <Button
              onClick={() => navigate(`${to}create`)}
              variant="outlined"
              to="#"
              startIcon={<Iconify icon="eva:plus-fill" />}
            >
              Nova imagem
            </Button>
          </ImageListItem>
        )}
      </ImageList>
    </Box>
  );
}

const ZoomableImage = ({ item = {}, to = '', handleClick = () => {}, handleDelete = null }) => {
  const [isZoomed, setIsZoomed] = useState(false);

  const handleZoomChange = useCallback(shouldZoom => {
    setIsZoomed(shouldZoom);
  }, []);

  return (
    <>
      <ControlledZoom isZoomed={isZoomed} onZoomChange={handleZoomChange}>
        <Box
          component="img"
          crossOrigin="anonymous"
          src={item.src}
          loading="lazy"
          alt={item?.title}
          sx={{
            width: '100%',
            height: '100%',
            maxHeight: 300,
            cursor: 'pointer !important',
            objectFit: 'cover !important',
            objectPosition: 'center center !important'
          }}
          {...(!isEmpty(to) && {
            role: 'button',
            onClick: () => handleClick(item.id)
          })}
        />
      </ControlledZoom>
      <ImageListItemBar
        title={item.title}
        subtitle={item.subtitle}
        actionIcon={
          <>
            <IconButton
              sx={{ color: 'rgba(255, 255, 255, 0.7)', cursor: 'zoom-in' }}
              onClick={() => setIsZoomed(true)}
            >
              <Iconify icon="bx:zoom-in" />
            </IconButton>
            {
              isFunction(handleDelete) && (
                <IconButton
                  sx={{ color: 'rgba(255, 255, 255, 0.7)', cursor: 'pointer' }}
                  onClick={() => handleDelete(item.id)}
                >
                  <Iconify icon="eva:trash-2-fill" />
                </IconButton>
              )
            }
          </>
        }
      />
    </>
  );
};

ZoomableImage.propTypes = {
  handleClick: PropTypes.func,
  handleDelete: PropTypes.func,
  item: PropTypes.object,
  to: PropTypes.string
}

ImageGallery.propTypes = propTypes;

export default React.memo(ImageGallery);
