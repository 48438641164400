/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable camelcase */

import React, {
  forwardRef,
  useImperativeHandle,
  useMemo,
  useRef,
  useEffect,
  useState
} from 'react';
import { isEmpty } from 'lodash';
import Uppy from '@uppy/core';
import { Dashboard } from '@uppy/react';
import ImageEditor from '@uppy/image-editor';
import Webcam from '@uppy/webcam';
import pt_BR from '@uppy/locales/lib/pt_BR';
import { Box, CircularProgress } from '@mui/material';

import { SystemError } from 'utils/SystemError';

import '@uppy/core/dist/style.min.css';
import '@uppy/dashboard/dist/style.min.css';
import '@uppy/image-editor/dist/style.min.css';
import '@uppy/webcam/dist/style.min.css';

import './style.css';

const errors = (min, max) => ([
  {
    id: 'FILE_NOT_LOADED',
    message: 'Nenhum arquivo carregado.'
  },
  {
    id: 'MINIMUM_FILE_NOT_REACHED',
    message: `Você não alcançou o mínimo de arquivos necessários. Mínimo: ${min}.`
  },
  {
    id: 'MAXIMUM_FILE_EXCEEDED',
    message: `Você ultrapassou o limite máximo de arquivos permitidos. Máximo: ${max}.`
  }
]);

const FileField = (
  {
    name,
    label = '',
    max = 1,
    min = 0,
    maxFileSize = 60,
    extensions = null,
    disabled = false,
    useImageEditor = true,
    useImageEditorOptions = {
      onlyCrop: false,
      aspectRatio: NaN
    },
    useWebcam = true
  },
  ref
) => {
  const fileQuantity = useRef(0);
  const error = useRef({
    id: '',
    message: ''
  });

  const [uppy, setUppy] = useState(null);

  const fileSize = useMemo(() => maxFileSize * 1024 * 1024, [maxFileSize]);
  const plugins = useMemo(() => {
    const list = [];

    if (useImageEditor) list.push('ImageEditor');
    if (useWebcam) list.push('Webcam');

    return list;
  }, [useImageEditor, useWebcam]);

  useEffect(() => {
    const uppyInstance = new Uppy({
      id: name,
      locale: {
        strings: {
          ...pt_BR.strings,
          save: 'Salvar'
        }
      },
      autoProceed: false,
      showProgressDetails: false,
      restrictions: {
        maxFileSize: fileSize,
        maxNumberOfFiles: max,
        minNumberOfFiles: min,
        allowedFileTypes: extensions
      }
    })
      .use(ImageEditor, {
        id: 'ImageEditor',
        quality: 1,
        cropperOptions: {
          autoCrop: 1,
          viewMode: 1,
          background: false,
          autoCropArea: 1,
          responsive: true,
          aspectRatio: useImageEditorOptions?.aspectRatio ?? NaN,
          rotatable: true,
          scalable: Boolean(!useImageEditorOptions?.onlyCrop),
          cropBoxResizable: true,
          zoomable: true,
          zoomOnTouch: true,
          zoomOnWheel: true
        },
        actions: {
          revert: false,
          rotate: true,
          granularRotate: true,
          flip: Boolean(!useImageEditorOptions?.onlyCrop),
          zoomIn: true,
          zoomOut: true,
          cropSquare: Boolean(!useImageEditorOptions?.onlyCrop),
          cropWidescreen: Boolean(!useImageEditorOptions?.onlyCrop),
          cropWidescreenVertical: Boolean(!useImageEditorOptions?.onlyCrop)
        }
      })
      .use(Webcam, {
        id: 'Webcam',
        modes: ['picture']
      })
      .on('file-added', () => {
        fileQuantity.current += 1;

        error.current = handleErrors();
      })
      .on('file-removed', () => {
        fileQuantity.current -= 1;

        error.current = handleErrors();
      });

    setUppy(uppyInstance);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getFiles = () => {
    const files = uppy.getFiles();

    return files.map(({ data }) => data);
  };

  const handleErrors = () => {
    const [
      FILE_NOT_LOADED,
      MINIMUM_FILE_NOT_REACHED,
      MAXIMUM_FILE_EXCEEDED
    ] = errors(min, max);

    const { current: quantity } = fileQuantity;

    if (min > 0) {
      if (quantity === 0) return FILE_NOT_LOADED;
      if (quantity < min) return MINIMUM_FILE_NOT_REACHED;
      if (max !== null && quantity > max) return MAXIMUM_FILE_EXCEEDED;
    }

    return {
      id: '',
      message: ''
    };
  };

  const hasError = () => new Promise((resolve, reject) => {
      if (!isEmpty(error.current.id) || fileQuantity.current === 0 && min > 0) {
        error.current = handleErrors();
  
        reject(new SystemError({
          data: {
            message: error.current.message
          }
        }));
      }
  
      return resolve(null);
    });

  useImperativeHandle(ref, () => ({
    error,
    getFiles,
    hasError
  }));

  return (
    <>
      {uppy ? (
        <Box sx={{ width: '100%' }}>
          {label && (
            <label className={`input-label ${error?.id ? ' label_error' : ''}`}>
              {label}
            </label>
          )}
          <div style={{ margin: '5px 0' }}>
            <Dashboard
              uppy={uppy}
              showProgressDetails={false}
              hideUploadButton
              proudlyDisplayPoweredByUppy={false}
              plugins={plugins}
              width="100%"
              autoOpenFileEditor={
                max === 1 && Boolean(useImageEditorOptions?.onlyCrop)
              }
              disabled={disabled}
            />
          </div>
        </Box>
      ) : <CircularProgress />}
    </>
  );
};

export default forwardRef(FileField);
