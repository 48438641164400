import { Box, CircularProgress } from '@mui/material';

function Loader() {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
      <CircularProgress sx={{ my: 5 }} />
    </Box>
  );
}

export default Loader;
