import useSWR from 'swr';
import HttpClient from 'utils/HttpClient';

function useFetcher(url, options = {}) {
  options = {
    shouldRetryOnError: false,
    ...options,
  };

  const { data, error, mutate } = useSWR(
    url,
    async url => HttpClient.get(url),
    options
  );

  const loading = !error && !data;

  return { data, error, loading, mutate };
}

export default useFetcher;
