export const TABLE_HEAD = [
  { id: 'name', label: 'Nome', alignRight: false, orderable: true },
  { id: 'value', label: 'Valor', alignRight: false, orderable: true },
  { id: 'type', label: 'Tipo', alignRight: false, orderable: false },
];

export const formatTableData = (data) => ({
  key: data?.id,
  columns: [
    { render: data?.setting.name },
    { render: data?.value }, 
    { render: data?.setting.type }
  ]
});
