import * as Yup from 'yup';

export default Yup.object().shape({
  name: Yup.string().label('Nome').required(),
  document: Yup.string().label('Documento').required(),
  code: Yup.string().label('Código').required(),

  address: Yup.object().shape({
    number : Yup.string().label('Numero').required(),
    street : Yup.string().label('Rua').required(),
    neighborhood : Yup.string().label('Bairro').required(),
    postalCode : Yup.string().label('Código Postal').required(),
    city : Yup.string().label('Cidade').required(),
    state : Yup.string().label('Estado').required().max(2).min(2),

    coordinates: Yup.object().shape({
      latitude : Yup.string().label('Latitude').required(),
      longitude : Yup.string().label('Longitude').required()
    })
  })
});
