import api from './api';
import { APIError } from './APIError';

class HttpClient {
  async get(path, config) {
    try {
      const response = await api.get(path, config);

      return response.data;
    } catch (error) {
      throw new APIError(error.response);
    }
  }

  async post(path, data, config) {
    try {
      const response = await api.post(path, data, config);

      return response.data;
    } catch (error) {
      throw new APIError(error.response);
    }
  }

  async patch(path, id, data, config) {
    try {
      const response = await api.patch(`${path}/${id}/`, data, config);

      return response.data;
    } catch (error) {
      throw new APIError(error.response);
    }
  }

  async put(path, id, config) {
    try {
      const response = await api.put(`${path}/${id}/`, config);

      return response.data;
    } catch (error) {
      throw new APIError(error.response);
    }
  }

  async delete(path, id, config) {
    try {
      const response = await api.delete(`${path}/${id}/`, config);

      return response.data;
    } catch (error) {
      throw new APIError(error.response);
    }
  }

}

export default new HttpClient();
