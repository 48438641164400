import { useParams } from 'react-router-dom';
import { Grid, Pagination } from '@mui/material';

import { io } from 'socket.io-client';
import Error from 'components/Error';
import PageContainer from 'components/PageContainer';
import OrderService from 'services/OrderService';

import useFetcher from 'hooks/useFetcher';
import { useEffect, useState } from 'react';
import Loader from 'components/Loader';
import { Box } from '@mui/system';
import { FormProvider, SelectField, TextField } from 'components/hook-form';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import OrderFilterSchema from '../utils/validations/OrderFilterValidation';
import OrderCard from '../components/OrderCard';
import { DELIVERY_STATUS } from '../utils/constants';

const socket = io(process.env.REACT_APP_SOCKET_URL);

function setFormInitialValues() {
  return {
    status: '',
  };
}

export default function Order() {
  const methods = useForm({
    resolver: yupResolver(OrderFilterSchema),
    defaultValues: setFormInitialValues()
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
    getValues
  } = methods;

  const { distributionCenterId } = useParams();
  const [page, setPage] = useState(1);

  const { data, loading, error } = useFetcher(
    OrderService.listAll(distributionCenterId, page, 15, {
      ...(getValues('status') && { status: getValues('status') }),
      ...(getValues('dateStart') && { dateStart: getValues('dateStart') }),
      ...(getValues('dateFinish') && { dateFinish: getValues('dateFinish') }),
    })
  );

  const [stackPedidos, setStackPedidos] = useState([]);

  useEffect(() => {
    if (data) {
      setStackPedidos(data?.items ?? []);
    }
  }, [data]);

  useEffect(() => {
    socket.on(distributionCenterId, order => {
      if (!stackPedidos.some(pedido => pedido.id === order.id)) {
        setStackPedidos(prevStackPedidos => [order, ...prevStackPedidos]);
      }
    });

    return () => {
      socket.off(distributionCenterId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [distributionCenterId]);

  const onSubmit = () => {};

  const getStatusList = () => (
    Object.entries(DELIVERY_STATUS).map(([key, value]) => (
      {
        label: value.name,
        value: key
      }
    ))
  );

  if (error) return <Error {...{ error }} />;

  return (
    <PageContainer title="Painel de Pedidos">
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Grid 
          container
          spacing={3}
          direction="row" 
          justifyContent="flex-end"
          mb={2}
        >
          <Grid item md={3} xs={12}>
            <SelectField
              name="status"
              label="Status"
              options={ getStatusList() }
            />
          </Grid>

          <Grid item md={3} xs={12}>
            <TextField 
              type="date" 
              name="dateStart" 
              label="Data Inicial"
              InputLabelProps={{
                shrink: true,
              }}   
            />
          </Grid>

          <Grid item md={3} xs={12}>
            <TextField 
              type="date" 
              name="dateFinish" 
              label="Data Final"
              InputLabelProps={{
                shrink: true,
              }}   
            />
          </Grid>

          <Grid item md={3} xs={12}>
            <LoadingButton
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
            >
              Filtrar
            </LoadingButton>
          </Grid>
        </Grid>
      </FormProvider>

      <Grid container spacing={1}>
        {loading ? (
          <Loader />
        ) : (
          stackPedidos.map((pedido, i) => <OrderCard pedido={pedido} key={i} />)
        )}
      </Grid>

      <Box sx={{ marginTop: 3, display: 'flex', justifyContent: 'center' }}>
        <Pagination
          count={data?.metadata?.totalPages ?? 1}
          onChange={(_, value) => setPage(value)}
          color="primary"
          size="large"
          showFirstButton
          showLastButton
        />
      </Box>
    </PageContainer>
  );
}
