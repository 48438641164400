/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
import { Autocomplete, TextField } from '@mui/material';
import { debounce, isEmpty } from 'lodash';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';

import useFetcher from 'hooks/useFetcher';

AsyncSelectField.propTypes = {
  label: PropTypes.string,
  labelKey: PropTypes.string,
  name: PropTypes.string,
  onInputChange: PropTypes.func,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  url: PropTypes.string,
  valueKey: PropTypes.string,
  defaultOptions: PropTypes.array
}

export default function AsyncSelectField({
  url,
  name,
  label,
  required,
  onInputChange = () => {},
  readOnly = false,
  valueKey = 'id',
  labelKey = 'name',
  defaultOptions = [],
  ...other
}) {
  const { data, loading } = useFetcher(url, { focusThrottleInterval: 5000 });

  const { control } = useFormContext();

  const [options, setOptions] = useState(defaultOptions);

  const handleChangeInputValue = (_, value) => {
    onInputChange(value);
  };

  const debouncedChangeInputValue = useCallback(
    debounce(handleChangeInputValue, 500),
    []
  );

  useEffect(
    () => () => {
      debouncedChangeInputValue.cancel();
    },
    []
  );

  const convertToOptions = (items) => items.map(item => ({
    label: item?.[labelKey],
    value: item?.[valueKey]
  }));

  useEffect(() => {
    if (!loading && !isEmpty(data?.items)) {
      setOptions(
        [
          ...convertToOptions(defaultOptions),
          ...convertToOptions(data?.items)
        ]
      );
    }
  }, [data, loading, labelKey, valueKey]);

  return (
    <Controller
      name={name}
      id={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <>
          <Autocomplete
            fullWidth
            options={options}
            loading={loading}
            autoComplete
            includeInputInList
            filterSelectedOptions
            getOptionLabel={option => option.label || ''}
            onChange={(_, item) => {
              onChange(item?.value ?? '');
            }}
            onInputChange={debouncedChangeInputValue}
            value={value}
            noOptionsText="Não há dados relacionados a sua pesquisa."
            loadingText="Carregando..."
            renderInput={params => (
              <TextField
                {...params}
                error={!!error}
                helperText={error?.message}
                label={label}
                required={required}
              />
            )}
            renderOption={(props, option, { inputValue }) => {
              const matches = match(option?.label, inputValue);
              const parts = parse(option?.label, matches);

              return (
                <li {...props}>
                  <div>
                    {parts.map((part, index) => (
                      <span
                        key={index}
                        style={{
                          fontWeight: part.highlight ? 700 : 400,
                        }}
                      >
                        {part.text}
                      </span>
                    ))}
                  </div>
                </li>
              );
            }}
            {...other}
            required={required}
            label={label}
            inputProps={{ readOnly }}
          />
        </>
      )}
    />
  );
}
