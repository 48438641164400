import * as Yup from 'yup';

export default Yup.object().shape({
  name: Yup.string().label('Nome').required(),
  description: Yup.string().label('Descrição').required(),
  price: Yup.number().label('Preço').required(),
  stock: Yup.number().label('Estoque').required(),
  toHighlight: Yup.bool().label('Destaque'),
  variantOf: Yup.string().label('Variante de').nullable()
});
