import { useCallback, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { debounce, isEmpty } from "lodash";

export default function useTable() {
  const navigate = useNavigate();
  const [search] = useSearchParams();

  const [page, setPage] = useState(1);
  const [prevPage, setPrevPage] = useState(1);
  const [order, setOrder] = useState('ASC');
  const [orderBy, setOrderBy] = useState('');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const filterSearch = search.get('search');

  const handleRequestSort = useCallback(
    (_, property) => {
      if (orderBy === property) {
        setOrder(order === 'ASC' ? 'DESC' : 'ASC');
        setOrderBy(order === 'ASC' ? property : '');

        return;
      }

      setOrder('ASC');
      setOrderBy(property);
    },
    [order, orderBy]
  );

  const handleChangePage = (_, newPage) => {
    setPage(Number(newPage) + 1);

    if (isEmpty(filterName)) {
      setPrevPage(Number(newPage) + 1);
    }
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handleFilterByName = event => {
    const { value } = event.target;

    setFilterName(value);
    debouncedChangeInputValue(value);
    setPage(isEmpty(value) ? prevPage : 1);
  };

  const handleSaveSearchQuery = (value) => {
    navigate(`?search=${value}`);
  };

  const debouncedChangeInputValue = useCallback(
    debounce(handleSaveSearchQuery, 500),
    []
  );

  useEffect(
    () => () => {
      debouncedChangeInputValue.cancel();
    },
    []
  );

  return {
    page,
    order,
    orderBy,
    filterName,
    filterSearch,
    rowsPerPage,
    handleRequestSort,
    handleChangePage,
    handleChangeRowsPerPage,
    handleFilterByName,
  };
}
