import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { Grid } from '@mui/material';
import { useActionDialog } from 'hooks/useActionDialog';
import { useActionSnackbar } from 'hooks/useActionSnackbar';
import { isEmpty } from 'lodash';

import { TextField, FormProvider } from 'components/hook-form';
import GridContainer from 'components/GridContainer';
import Error from 'components/Error';
import Loader from 'components/Loader';
import PageContainer from 'components/PageContainer';
import ImageGallery from 'components/ImageGallery';

import useFetcher from 'hooks/useFetcher';
import BrandsService from 'services/BrandsService';
import BrandsImagesService from 'services/BrandsImagesService';
import { formatGalleryData } from '../utils/table';

function BrandPage() {
  const methods = useForm({});
  const navigate = useNavigate();
  const { addActionSnackbar } = useActionSnackbar();
  const { brandId } = useParams();
  const { distributionCenterId } = useParams();
  const { addActionDialog } = useActionDialog();
  const { data, loading, error, mutate } = useFetcher(
    BrandsService.findById(brandId)
  );

  function onAccept(){
    handleDelete(brandId);
  }

  function handleConfirmDelete(){
    addActionDialog({
      message: 'Deseja realmente deletar esta marca?',
      title: 'Confirmação de exclusão',
      onAccept
    });
  }

  function handleUpdate(id){
    navigate(`/dashboard/distribution-centers/${distributionCenterId}/brands/${id}/update`);
  }

  const handleDelete = async id => {
    try {
      const res = await BrandsService.delete(id);
      
      if (!isEmpty(res)) {
        addActionSnackbar({
          severity: 'success',
          message: 'Marca deletada com sucesso!'
        });
        navigate(-1);
      }
    } catch (error) {
      addActionSnackbar({
        severity: 'error',
        message: error.message,
        statusList: error.badFields
      });
    }
  }
  useEffect(() => {
    methods.reset({
      name: data?.name || ''
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleDeleteImage = async id => {
    try {
      const res = await BrandsImagesService.delete(id);
      
      if (!isEmpty(res)) {
        addActionSnackbar({
          severity: 'success',
          message: 'Imagem deletada com sucesso!'
        });

        mutate();
      }
    } catch (error) {
      addActionSnackbar({
        severity: 'error',
        message: error.message,
        statusList: error.badFields
      });
    }
  }

  if (error) return <Error {...{ error }} />;

  return (
    <PageContainer
      title="Painel de Marca"
      menuOptions={{
        editable: () => handleUpdate(data?.id),
        deletable: () => handleConfirmDelete(data?.id)
      }}
    >
      {loading ? (
        <Loader />
      ) : (
        <FormProvider methods={methods}>
          <GridContainer>
            <Grid item xs={12}>
              <TextField 
                type="text"
                name="name"
                label="Nome"
                readOnly
              />
            </Grid>
          </GridContainer>

          <ImageGallery 
            to={`/dashboard/distribution-centers/${distributionCenterId}/brands/${brandId}/images/`}
            data={[...(!isEmpty(data?.image) ? [data?.image] : [])]}
            formatImagesData={formatGalleryData}
            cols={2}
            addMore={isEmpty(data?.image)}
            onDelete={(imageId) => handleDeleteImage(imageId)}
            isClickable={false}
          />
        </FormProvider>
      )}
    </PageContainer>
  );
}

export default BrandPage;