import PropTypes from "prop-types"
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { FormProvider, TextField } from 'components/hook-form';
import { isEmpty } from 'lodash';
import { SystemError } from 'utils/SystemError';
import confirmAccessSchema from './validation';

function setFormInitialValues() {
  return {
    password: ''
  };
}

function ConfirmAccessForm({ onSubmit = () => {}, resolve, reject, removeConfirmAccess }) {
  const [loading, setLoading] = useState(false);

  const methods = useForm({
    resolver: yupResolver(confirmAccessSchema),
    defaultValues: setFormInitialValues()
  });

  const {
    handleSubmit,
    formState: { isSubmitting }
  } = methods;

  const handleOnSubmit = ({ password }) => {
    setLoading(true);

    setTimeout(() => {
      setLoading(false);
      onSubmit();

      if (!isEmpty(password)) {
        resolve(password);
        removeConfirmAccess();

        return;
      }

      reject(new SystemError({
        data: {
          status: 401,
          message: 'A senha é invalida!'
        }
      }));
      removeConfirmAccess();
    }, 500);
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(handleOnSubmit)}>
      <Stack spacing={3} sx={{ mt: 5 }}>
        <TextField type="password" name="password" label="Senha" required autoComplete="off" />
        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting || loading}
        >
          Confirmar
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
}

ConfirmAccessForm.propTypes = {
  onSubmit: PropTypes.func,
  reject: PropTypes.func,
  removeConfirmAccess: PropTypes.func,
  resolve: PropTypes.func
}

export default React.memo(ConfirmAccessForm);
