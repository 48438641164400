import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { Grid } from '@mui/material';
import { useActionDialog } from 'hooks/useActionDialog';
import { useActionSnackbar } from 'hooks/useActionSnackbar';
import { isEmpty } from 'lodash';

import { TextField, FormProvider } from 'components/hook-form';
import GridContainer from 'components/GridContainer';
import Error from 'components/Error';
import Loader from 'components/Loader';
import PageContainer from 'components/PageContainer';

import useFetcher from 'hooks/useFetcher';
import DiscountsService from 'services/DiscountsService';
import { fDateTimeS } from 'utils/formatTime';

function DiscountsPage() {
  const methods = useForm({});
  const navigate = useNavigate();
  const { discountId } = useParams();

  const { addActionSnackbar } = useActionSnackbar();
  const { addActionDialog } = useActionDialog();
  const { data, loading, error } = useFetcher(
    DiscountsService.findById(discountId)
  );

  function onAccept() {
    handleDelete(discountId);
  }

  function handleConfirmDelete() {
    addActionDialog({
      message: 'Deseja realmente deletar este desconto?',
      title: 'Confirmação de exclusão',
      onAccept
    });
  }

  const handleDelete = async id => {
    try {
      const res = await DiscountsService.delete(id);

      if (!isEmpty(res)) {
        addActionSnackbar({
          severity: 'success',
          message: 'Desconto deletado com sucesso!'
        });

        navigate(-1);
      }
    } catch (error) {
      addActionSnackbar({
        severity: 'error',
        message: error.message,
        statusList: error.badFields
      });
    }
  };

  useEffect(() => {
    methods.reset({
      discountValue: data?.value ? `${data?.value}%` : '0%',
      discountMinQuantity: data?.minimumQuantity || 0,
      discountMaxQuantity: data?.maximumQuantity || 0,
      discountExpiresAt: data?.expiresAt ? fDateTimeS(data?.expiresAt) : ''
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (error) return <Error {...{ error }} />;

  return (
    <PageContainer
      title="Painel de desconto"
      menuOptions={{
        deletable: () => handleConfirmDelete(data?.id)
      }}
    >
      {loading ? (
        <Loader />
      ) : (
        <FormProvider methods={methods}>
          <GridContainer>
            <Grid item xs={12}>
              <TextField
                type="text"
                name="discountValue"
                label="Desconto"
                autoComplete="off"
                required
                readOnly
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                type="number"
                name="discountMinQuantity"
                label="Mínima para desconto"
                autoComplete="off"
                required
                readOnly
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                type="number"
                name="discountMaxQuantity"
                label="Máxima para desconto"
                autoComplete="off"
                readOnly
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                type="text"
                name="discountExpiresAt"
                label="Desconto expira em"
                autoComplete="off"
                required
                readOnly
              />
            </Grid>
          </GridContainer>
        </FormProvider>
      )}
    </PageContainer>
  );
}

export default DiscountsPage;
