import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { Grid } from '@mui/material';
import { useActionDialog } from 'hooks/useActionDialog';
import { useActionSnackbar } from 'hooks/useActionSnackbar';
import { isEmpty } from 'lodash';

import { TextField, FormProvider } from 'components/hook-form';
import GridContainer from 'components/GridContainer';
import Error from 'components/Error';
import Loader from 'components/Loader';
import PageContainer from 'components/PageContainer';

import useFetcher from 'hooks/useFetcher';
import TypesPaymentsService from 'services/TypesPaymentsService';

function TypesPaymentsPage() {
  const methods = useForm({});
  const navigate = useNavigate();
  const { addActionSnackbar } = useActionSnackbar();
  const { addActionDialog } = useActionDialog();

  const { typePaymentId, distributionCenterId } = useParams();

  const { data, loading, error } = useFetcher(
    TypesPaymentsService.findById(typePaymentId)
  );

  function onAccept() {
    handleDelete(typePaymentId);
  }

  function handleConfirmDelete() {
    addActionDialog({
      message: 'Deseja realmente deletar este tipo de pagamento?',
      title: 'Confirmação de exclusão',
      onAccept
    });
  }

  function handleUpdate(id) {
    navigate(
      `/dashboard/distribution-centers/${distributionCenterId}/types-payments/${id}/update`
    );
  }

  const handleDelete = async id => {
    try {
      const res = await TypesPaymentsService.delete(id);

      if (!isEmpty(res)) {
        addActionSnackbar({
          severity: 'success',
          message: 'Tipo de pagamento deletado com sucesso!'
        });

        navigate(-1);
      }
    } catch (error) {
      addActionSnackbar({
        severity: 'error',
        message: error.message,
        statusList: error.badFields
      });
    }
  };
  useEffect(() => {
    methods.reset({
      name: data?.name || ''
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (error) return <Error {...{ error }} />;

  return (
    <PageContainer
      title="Painel de Tipo de Pagamento"
      menuOptions={{
        editable: () => handleUpdate(data?.id),
        deletable: () => handleConfirmDelete(data?.id)
      }}
    >
      {loading ? (
        <Loader />
      ) : (
        <FormProvider methods={methods}>
          <GridContainer>
            <Grid item xs={12}>
              <TextField type="text" name="name" label="Nome" readOnly />
            </Grid>
          </GridContainer>
        </FormProvider>
      )}
    </PageContainer>
  );
}

export default TypesPaymentsPage;
