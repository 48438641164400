import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { isEmpty } from 'lodash';
import { Grid } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { yupResolver } from '@hookform/resolvers/yup';
import { fTimeZone } from 'utils/formatTime';

import PageContainer from 'components/PageContainer';
import GridContainer from 'components/GridContainer';
import {
  FormProvider,
  TextField
} from 'components/hook-form';

import { useActionSnackbar } from 'hooks/useActionSnackbar';

import DiscountsService from 'services/DiscountsService';
import DiscountsCreateSchema from '../utils/validations/DiscountsCreateValidation';

function setFormInitialValues() {
  return {
    value: '',
    minimumQuantity: '',
    maximumQuantity: '',
    expiresAt: '',
    unit: 0
  };
}

function DiscountsCreate() {
  const [loading, setLoading] = useState(false);

  const { productId } = useParams();
  const { distributionCenterId } = useParams();
  const { addActionSnackbar } = useActionSnackbar();

  const navigate = useNavigate();
  const methods = useForm({
    resolver: yupResolver(DiscountsCreateSchema),
    defaultValues: setFormInitialValues()
  });

  const {
    handleSubmit,
    formState: { isSubmitting }
  } = methods;

  const onSubmit = async data => {
    setLoading(true);

    try {
      data.productId = productId;
      data.expiresAt = fTimeZone(data.expiresAt);

      const res = await DiscountsService.create(data);

      if (!isEmpty(res)) {
        addActionSnackbar({
          severity: 'success',
          message: 'Desconto cadastrado com sucesso!'
        });
        navigate(
          `/dashboard/distribution-centers/${distributionCenterId}/products/${productId}`
        );
      }
    } catch (error) {
      addActionSnackbar({
        severity: 'error',
        message: error.message,
        statusList: error.badFields
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <PageContainer title="Cadastrar desconto">
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <GridContainer>
          <Grid item xs={12}>
            <TextField
              type="number"
              name="value"
              label="Desconto"
              autoComplete="off"
              required
            />
          </Grid>
          
          <Grid item xs={12}>
            <TextField
              type="number"
              name="minimumQuantity"
              label="Quantidade mínima"
              autoComplete="off"
              required
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              type="number"
              name="maximumQuantity"
              label="Quantidade máxima"
              autoComplete="off"
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              type="date"
              name="expiresAt"
              label="Expira em"
              autoComplete="off"
              required
            />
          </Grid>
          <Grid item xs={12}>
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting || loading}
            >
              Cadastrar
            </LoadingButton>
          </Grid>
        </GridContainer>
      </FormProvider>
    </PageContainer>
  );
}

export default DiscountsCreate;
