import { isEmpty } from "lodash";
import { stringify } from "qs";

const mountQuery = (url, query) => {
  if (isEmpty(query)) return url;

  const q = stringify(query, { allowDots: true });

  return `${url}?${q}`;
};

export default mountQuery;
