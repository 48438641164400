import HttpClient from 'utils/HttpClient';
import mountQuery from 'utils/mountQuery';

class BrandsService {
  findAll(page = 1, limit = 10, sortBy = {}, filterBy = {}) {
    return mountQuery('/brands', { page, limit, sortBy, filterBy });
  }

  findById(id) {
    return `/brands/${id}`;
  }

  findByDistributionCenter(id, page = 1, limit = 10, sortBy = {}, filterBy = {} ) {
    return mountQuery(`/brands/by/distribution-center/${id}`, {
      page,
      limit,
      sortBy,
      filterBy 
    });
  }

  async create(data) {
    return HttpClient.post('/brands', data);
  }

  async update(id, data) {
    return HttpClient.put(`/brands`, id, { 
      name: data.name
    });
  }

  async delete(id) {
    return HttpClient.delete(`/brands`, id);
  }
}

export default new BrandsService();
