import Error from 'components/Error';
import Table from 'components/table/Table';
import PageContainer from 'components/PageContainer';

import useFetcher from 'hooks/useFetcher';
import useTable from 'hooks/useTable';

import ClientsService from 'services/ClientsService';

import { formatTableData, TABLE_HEAD } from '../utils/table';

export default function Clients() {
  const {
    page,
    order,
    orderBy,
    filterName,
    filterSearch,
    rowsPerPage,
    handleRequestSort,
    handleChangePage,
    handleChangeRowsPerPage,
    handleFilterByName
  } = useTable();

  const { data, loading, error } = useFetcher(
    ClientsService.findAll(
      page,
      rowsPerPage,
      { [orderBy]: order },
      { search: filterSearch }
    )
  );

  if (error) return <Error {...{ error }} />;

  return (
    <PageContainer
      title="Painel de Clientes"
    >
      <Table
        data={data}
        loading={loading}
        tableHead={TABLE_HEAD}
        order={order}
        orderBy={orderBy}
        rowsPerPage={rowsPerPage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        filterName={filterName}
        onFilterName={handleFilterByName}
        onRequestSort={handleRequestSort}
        formatTableData={formatTableData}
        to="./"
      />
    </PageContainer>
  );
}
