import HttpClient from 'utils/HttpClient';

class AuthService {
  async signin(user) {
    const { email, password } = user;

    return HttpClient.post('/auth/login', {
      email,
      password
    });
  }
}

export default new AuthService();
