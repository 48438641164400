import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { Grid } from '@mui/material';
import { useActionDialog } from 'hooks/useActionDialog';
import { TextField, FormProvider } from 'components/hook-form';
import { fDateTimeS } from 'utils/formatTime';
import { isEmpty } from 'lodash';
import { useActionSnackbar } from 'hooks/useActionSnackbar';
import useFetcher from 'hooks/useFetcher';
import GridContainer from 'components/GridContainer';
import Error from 'components/Error';
import Loader from 'components/Loader';
import PageContainer from 'components/PageContainer';
import DcService from 'services/DistributionCentersService';

function DistributionCenterPage() {
  const methods = useForm({});
  const navigate = useNavigate();
  const { addActionSnackbar } = useActionSnackbar();
  const { distributionCenterId } = useParams();
  const { addActionDialog } = useActionDialog();
  const { data, loading, error } = useFetcher(
    DcService.findById(distributionCenterId)
  );

  function onAccept(){
    handleDelete(distributionCenterId);
  }

  function handleConfirmDelete(){
    addActionDialog({
      message: 'Deseja realmente deletar este centro de distribuição?',
      title: 'Confirmação de exclusão',
      onAccept
    });
  }

  function handleUpdate(id){
    navigate(`/dashboard/distribution-centers/${id}/update`);
  }

  function handleCategories(id){
    navigate(`/dashboard/distribution-centers/${id}/categories`);
  }

  function handleBrands(id){
    navigate(`/dashboard/distribution-centers/${id}/brands`);
  }

  function handleProducts(id){
    navigate(`/dashboard/distribution-centers/${id}/products`);
  }

  function handleOrders(id){
    navigate(`/dashboard/distribution-centers/${id}/orders`);
  }

  function handleSettings(id){
    navigate(`/dashboard/distribution-centers/${id}/settings`);
  }

  function handleTypesPayments(id){
    navigate(`/dashboard/distribution-centers/${id}/types-payments`);
  }

  function handleOperatingDays(id) {
    navigate(`/dashboard/distribution-centers/${id}/operating-days`);
  }

  const handleDelete = async id => {
    try {
      const res = await DcService.delete(id);
      
      if (!isEmpty(res)) {
        addActionSnackbar({
          severity: 'success',
          message: 'Centro de distribuição deletado com sucesso!'
        });
        navigate("/dashboard/distribution-centers");
      }
    } catch (error) {
      addActionSnackbar({
        severity: 'error',
        message: error.message,
        statusList: error.badFields
      });
    }
  }
  useEffect(() => {
    methods.reset({
      name: data?.name || '',
      document: data?.document || '',
      code: data?.code || '',
      number: data?.address.number || '',
      street: data?.address.street || '',
      neighborhood: data?.address.neighborhood || '',
      postalCode: data?.address.postalCode || '',
      city: data?.address.city || '',
      state: data?.address.state || '',
      latitude: data?.address.coordinates.latitude || '',
      longitude: data?.address.coordinates.longitude || '',
      created: data?.createdAt ? fDateTimeS(data?.createdAt) : ''
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (error) return <Error {...{ error }} />;

  return (
    <PageContainer
      title="Painel do Centro de distribuição"
      menuOptions={{
        editable: () => handleUpdate(data?.id),
        deletable: () => handleConfirmDelete(data?.id),
        options: [
          {
            label: 'Categorias',
            icon: 'bxs:category',
            action: () => handleCategories(data?.id)
          },
          {
            label: 'Marcas',
            icon: 'ant-design:tag-filled',
            action: () => handleBrands(data?.id)
          },
          {
            label: 'Produtos',
            icon: 'icon-park-solid:buy',
            action: () => handleProducts(data?.id)
          },
          {
            label: 'Pedidos',
            icon: 'icon-park-solid:buy',
            action: () => handleOrders(data?.id)
          },
          {
            label: 'Tipos de Pagamento',
            icon: 'mdi:money',
            action: () => handleTypesPayments(data?.id)
          },
          {
            label: 'Dias de Funcionamento',
            icon: 'material-symbols:calendar-add-on',
            action: () => handleOperatingDays(data?.id)
          },
          {
            label: 'Configurações',
            icon: 'eva:settings-fill',
            action: () => handleSettings(data?.id)
          }
        ]
      }}
    >
      {loading ? (
        <Loader />
      ) : (
        <FormProvider methods={methods}>
          <GridContainer>

            <Grid item xs={12}>
              <TextField 
                type="text" 
                name="name" 
                label="Nome" 
                readOnly
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                type="text"
                name="document"
                mask="cnpj"
                label="Documento"
                readOnly
              />
            </Grid>

            <Grid item xs={12}>
              <TextField 
                type="text" 
                name="code" 
                label="Código" 
                readOnly
              />
            </Grid>

            <Grid item xs={12}>
              <TextField 
                type="text" 
                name="street" 
                label="Rua" 
                readOnly 
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                type="text"
                name="neighborhood"
                label="Bairro"
                readOnly
              />
            </Grid>
            
            <Grid item xs={12}>
              <TextField 
                type="text" 
                name="number" 
                label="Número" 
                readOnly 
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                type="text"
                name="postalCode"
                label="CEP"
                mask="cep"
                readOnly
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                type="text"
                name="city"
                label="Cidade"
                readOnly
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                type="text"
                name="state"
                label="Estado"
                readOnly
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                type="text"
                name="latitude"
                label="Latitude"
                readOnly
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                type="text"
                name="longitude"
                label="Longitude"
                readOnly
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                type="text"
                name="created"
                label="Data de criação"
                readOnly
              />
            </Grid>

          </GridContainer>
        </FormProvider>
      )}
    </PageContainer>
  );
}

export default DistributionCenterPage;