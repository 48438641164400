import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { Grid, Typography } from '@mui/material';
import { useActionDialog } from 'hooks/useActionDialog';
import { useActionSnackbar } from 'hooks/useActionSnackbar';
import { isEmpty } from 'lodash';

import { TextField, FormProvider } from 'components/hook-form';
import GridContainer from 'components/GridContainer';
import Error from 'components/Error';
import Loader from 'components/Loader';
import PageContainer from 'components/PageContainer';
import ImageGallery from 'components/ImageGallery';

import useFetcher from 'hooks/useFetcher';
import ProductsService from 'services/ProductsService';
import ProductsImagesService from 'services/ProductsImagesService';
import { fDateTimeS } from 'utils/formatTime';
import { formatGalleryData } from '../utils/table';

function ProductPage() {
  const methods = useForm({});
  const navigate = useNavigate();
  const { addActionSnackbar } = useActionSnackbar();
  const { productId } = useParams();
  const { distributionCenterId } = useParams();
  const { addActionDialog } = useActionDialog();
  const { data, loading, error, mutate } = useFetcher(
    ProductsService.findById(productId)
  );

  function onAccept() {
    handleDelete(productId);
  }

  function handleConfirmDelete() {
    addActionDialog({
      message: 'Deseja realmente deletar este produto?',
      title: 'Confirmação de exclusão',
      onAccept
    });
  }

  function handleUpdate(id) {
    navigate(
      `/dashboard/distribution-centers/${distributionCenterId}/products/${id}/update`
    );
  }

  function handleDiscounts(id) {
    navigate(
      `/dashboard/distribution-centers/${distributionCenterId}/products/${id}/discounts`
    );
  }

  const handleDelete = async id => {
    try {
      const res = await ProductsService.delete(id);

      if (!isEmpty(res)) {
        addActionSnackbar({
          severity: 'success',
          message: 'Produto deletado com sucesso!'
        });
        navigate(-1);
      }
    } catch (error) {
      addActionSnackbar({
        severity: 'error',
        message: error.message,
        statusList: error.badFields
      });
    }
  };
  useEffect(() => {
    methods.reset({
      name: data?.name || '',
      description: data?.description || '',
      price: data?.price || 0,
      stock: data?.stock || 0,
      unit: data?.unit || 0,
      unitType: data?.unitType || '',
      brand: data?.brand.name || '',
      category: data?.category.name || '',
      toHighlight: data?.toHighlight ? 'Sim' : 'Não',
      ...(!isEmpty(data?.discount) && {
        discountValue: data?.discount.value ? `${data?.discount.value}%` : '0%',
        discountMinQuantity: data?.discount.minimumQuantity || 0,
        discountMaxQuantity: data?.discount.maximumQuantity || 0,
        discountExpiresAt: data?.discount.expiresAt ? fDateTimeS(data?.discount.expiresAt) : ''
      }),
      variantOf: data?.variantOf?.name || ''
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleDeleteImage = async id => {
    try {
      const res = await ProductsImagesService.delete(id);

      if (!isEmpty(res)) {
        addActionSnackbar({
          severity: 'success',
          message: 'Imagem deletada com sucesso!'
        });

        mutate();
      }
    } catch (error) {
      addActionSnackbar({
        severity: 'error',
        message: error.message,
        statusList: error.badFields
      });
    }
  };

  if (error) return <Error {...{ error }} />;

  return (
    <PageContainer
      title="Painel de Produto"
      menuOptions={{
        editable: () => handleUpdate(data?.id),
        deletable: () => handleConfirmDelete(data?.id),
        options: [
          {
            label: 'Descontos',
            icon: 'fontisto:shopping-sale',
            action: () => handleDiscounts(data?.id)
          }
        ]
      }}
    >
      {loading ? (
        <Loader />
      ) : (
        <FormProvider methods={methods}>
          <GridContainer>
            <Grid item xs={12}>
              <TextField
                type="text"
                name="name"
                label="Nome"
                autoComplete="off"
                required
                readOnly
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                type="text"
                name="description"
                label="Descrição"
                autoComplete="off"
                multiline
                rows={3}
                required
                readOnly
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                type="text"
                name="brand"
                label="Marca"
                autoComplete="off"
                required
                readOnly
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                type="text"
                name="category"
                label="Categoria"
                autoComplete="off"
                required
                readOnly
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                type="number"
                name="price"
                label="Preço"
                autoComplete="off"
                required
                readOnly
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                type="number"
                name="stock"
                label="Estoque"
                autoComplete="off"
                required
                readOnly
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                type="number"
                name="unit"
                label="Unidade"
                autoComplete="off"
                required
                readOnly
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                type="text"
                name="unitType"
                label="Tipo da unidade"
                autoComplete="off"
                required
                readOnly
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                type="text"
                name="variantOf"
                label="Variante de"
                autoComplete="off"
                required
                readOnly
              />
            </Grid>

            {!isEmpty(data?.discount) && (
              <>
                <Grid item xs={12}>
                  <Typography variant="h6" paragraph sx={{ marginBottom: 1 }}>
                    Desconto ativo:
                  </Typography>

                  <TextField
                    type="text"
                    name="discountValue"
                    label="Desconto"
                    autoComplete="off"
                    required
                    readOnly
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    type="number"
                    name="discountMinQuantity"
                    label="Mínima para desconto"
                    autoComplete="off"
                    required
                    readOnly
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    type="text"
                    name="discountExpiresAt"
                    label="Desconto expira em"
                    autoComplete="off"
                    required
                    readOnly
                  />
                </Grid>
              </>
            )}

            <Grid item xs={12} sm={6}>
              <TextField
                type="text"
                name="toHighlight"
                label="Está em destaque?"
                autoComplete="off"
                required
                readOnly
              />
            </Grid>

            <Grid item xs={12}>
              <ImageGallery
                to={`/dashboard/distribution-centers/${distributionCenterId}/products/${productId}/images/`}
                data={data?.images}
                formatImagesData={formatGalleryData}
                cols={2}
                addMore={data?.images?.length < 6}
                onDelete={imageId => handleDeleteImage(imageId)}
                isClickable={false}
              />
            </Grid>
          </GridContainer>
        </FormProvider>
      )}
    </PageContainer>
  );
}

export default ProductPage;
