import HttpClient from 'utils/HttpClient';
import mountQuery from 'utils/mountQuery';

class TypesPaymentsService {
  constructor() {
    this.url = '/types-payments';
  }

  findById(id) {
    return `${this.url}/${id}`;
  }

  findByDistributionCenter(id, page = 1, limit = 10, sortBy = {}, filterBy = {} ) {
    return mountQuery(`${this.url}/by/distribution-center/${id}`,{ 
      page,
      limit,
      sortBy,
      filterBy 
    });
  }

  async create(data) {
    return HttpClient.post(this.url, {
      name: data.name,
      distributionCenterId: data.distributionCenterId
    });
  }

  async update(id, data) {
    return HttpClient.put(this.url, id, { 
      name: data.name,
    });
  }

  async delete(id) {
    return HttpClient.delete(this.url, id);
  }
}

export default new TypesPaymentsService();
