import { useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Grid } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import PageContainer from 'components/PageContainer';
import GridContainer from 'components/GridContainer';
import { FileField, FormProvider } from 'components/hook-form';

import { useActionSnackbar } from 'hooks/useActionSnackbar';
import ProductsImagesService from 'services/ProductsImagesService';

import dataToFormData from 'utils/dataToFormData';
import { isEmpty } from 'lodash';

function ProductsImagesCreate() {
  const [loading, setLoading] = useState(false);
  const inputRef = useRef(null);
  
  const { productId, distributionCenterId } = useParams();
  const navigate = useNavigate();
  
  const { addActionSnackbar } = useActionSnackbar();

  const methods = useForm({});

  const {
    handleSubmit,
    formState: { isSubmitting }
  } = methods;

  const onSubmit = async data => {
    setLoading(true);

    try {
      data.productId = productId;

      await inputRef.current.hasError();

      const inputFile = inputRef.current.getFiles();

      const formData = dataToFormData(data);
      
      inputFile.forEach(files => {
        formData.append('file', files);
      });

      const res = await ProductsImagesService.create(formData);

      if (!isEmpty(res)) {
        addActionSnackbar({
          severity: 'success',
          message: 'Imagem para o produto cadastrada com sucesso!'
        });

        navigate(`/dashboard/distribution-centers/${distributionCenterId}/products/${productId}/`);
      }
    } catch (error) {
      addActionSnackbar({
        severity: 'error',
        message: error.message,
        statusList: error?.badFields
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <PageContainer title="Cadastrar Imagem para Produto">
       <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <GridContainer>
          <Grid item xs={12}>
            <FileField
              name="images"
              ref={inputRef}
              label="Selecione uma ou mais imagens:"
              min={1}
              max={6}
              extensions={['image/*']}
            />
          </Grid>

          <Grid item xs={12}>
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting || loading}
            >
              Cadastrar
            </LoadingButton>
          </Grid>

        </GridContainer>
      </FormProvider>
    </PageContainer>
  );
}

export default ProductsImagesCreate;
