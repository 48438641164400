import HttpClient from 'utils/HttpClient';
import mountQuery from 'utils/mountQuery';

class OrderService {
  listAll(id, page = 1, limit = 15, filterBy = {}) {
    return mountQuery(`/orders/by/distributionCenter/${id}`, { 
      page,
      limit,
      filterBy
    });
  }

  listAllByClient(id, page = 1, limit = 15) {
    return mountQuery(`/orders/by/distributionCenter/fc66e16f-6f15-4017-9210-1b3621886087`, { 
      page,
      limit,
      filterBy:{ clientId: id }
    });
  }

  sendStatus(newStatus, PedidoId) {
    return HttpClient.put(`/orders/sendStatus`, PedidoId, {
      status: newStatus
    });
  }
}

export default new OrderService();
