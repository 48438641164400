import React from 'react';
import { styled } from '@mui/material/styles';
import { Container, Typography } from '@mui/material';
import PropTypes from 'prop-types';

import Page from 'components/Page';

const getRootStyleFixed = fixed => {
  if (fixed) {
    return {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      zIndex: '1200'
    };
  }

  return {};
};

const RootStyle = styled('div')(({ theme, fixed }) => ({
  background: '#fff',
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  },
  ...getRootStyleFixed(fixed)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0)
}));

function PageContainerCentered({ title, children, fixed = false }) {
  return (
    <Page {...{ title }}>
      <RootStyle fixed={fixed}>
        <Container maxWidth="sm">
          <ContentStyle>
            <Typography variant="h4" gutterBottom align="center">
              {title}
            </Typography>

            {children}
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}

PageContainerCentered.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  title: PropTypes.string,
  fixed: PropTypes.bool
};

export default React.memo(PageContainerCentered);
