import React from 'react';
import PropTypes from 'prop-types';
import { Alert, AlertTitle, Typography } from '@mui/material';

const propTypes = {
  severity: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  statusList: PropTypes.arrayOf(PropTypes.string)
};

function Bullet() {
  return (
    <Typography sx={{ fontSize: 16, display: 'inline' }}>&#8226;</Typography>
  );
}

function ActionSnackbar({ severity, message, statusList = [] }) {
  return (
    <Alert variant="filled" severity={severity} sx={{ width: '100%' }}>
      {statusList.length > 0 ? (
        <>
          <AlertTitle>{message}</AlertTitle>
          {statusList.map((status, i) => (
            <span key={String(i)}>
              <Bullet /> {status}
            </span>
          ))}
        </>
      ) : (
        <>{message}</>
      )}
    </Alert>
  );
}

ActionSnackbar.propTypes = propTypes;

export default React.memo(ActionSnackbar);
