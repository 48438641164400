import { fShortenNumber } from "utils/formatNumber";
import { fDateTimeS } from "utils/formatTime";

export const TABLE_HEAD = [
  { id: 'name', label: 'Produto', alignRight: false, orderable: true },
  { id: 'price', label: 'Preço', alignRight: false, orderable: true },
  { id: 'stock', label: 'Estoque', alignRight: false, orderable: true },
  { id: 'unit', label: 'Unidade', alignRight: false, orderable: true },
  { id: 'toHighlight', label: 'Destaque?', alignRight: false, orderable: true },
  { id: 'hasImage', label: 'Imagem inserida?', alignRight: false, orderable: false }
];

export const formatTableData = (data) => ({
  key: data?.id,
  columns: [
    { render: data?.name },
    { render: `R$ ${data?.price}` },
    { render: data?.stock },
    { render: `${fShortenNumber(data?.unit)}${data?.unitType}` },
    { render: data?.toHighlight ? 'Sim' : 'Não' },
    { render: data?.images?.length > 0 ? 'Sim' : 'Não' }
  ]
});

export const formatGalleryData = (data) => ({
  id: data?.id,
  src: data?.url,
  title: data?.type,
  subtitle: `Criado em: ${fDateTimeS(data?.createdAt)}`
});

