import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { Grid } from '@mui/material';
import { TextField, FormProvider } from 'components/hook-form';
import { fDateTimeS } from 'utils/formatTime';
import useFetcher from 'hooks/useFetcher';
import GridContainer from 'components/GridContainer';
import Error from 'components/Error';
import Loader from 'components/Loader';
import PageContainer from 'components/PageContainer';
import ClientsService from 'services/ClientsService';

function ClientPage() {
  const methods = useForm({});
  const navigate = useNavigate();
  const { clientId } = useParams();
  const { data, loading, error } = useFetcher(
    ClientsService.findById(clientId)
  );
  
  /*
  function handleOperatingDays(id) {
    navigate(`/dashboard/distribution-centers/${id}/operating-days`);
  }
  */
  function handleOrders(id) {    
    navigate(`/dashboard/clients/${id}/orders`);
  }
 
  useEffect(() => {
    methods.reset({
      name: data?.name || '',
      email: data?.email || '',
      phone: data?.phone || '',
      created: data?.createdAt ? fDateTimeS(data?.createdAt) : ''
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (error) return <Error {...{ error }} />;

  return (
    <PageContainer
      title="Painel do Cliente"
      menuOptions={{
        options: [
          {
            label: 'Pedidos',
            icon: 'icon-park-solid:buy',
            action: () => handleOrders(data?.id)
          },
        ]
      }}
    >
      {loading ? (
        <Loader />
      ) : (
        <FormProvider methods={methods}>
          <GridContainer>

            <Grid item xs={12}>
              <TextField 
                type="text" 
                name="name" 
                label="Nome" 
                readOnly
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                type="text"
                name="email"
                label="Email"
                readOnly
              />
            </Grid>

            <Grid item xs={12}>
              <TextField 
                type="text" 
                name="phone"
                mask='phone' 
                label="Telefone" 
                readOnly
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                type="text"
                name="created"
                label="Data de criação"
                readOnly
              />
            </Grid>

          </GridContainer>
        </FormProvider>
      )}
    </PageContainer>
  );
}

export default ClientPage;